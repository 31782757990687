import React, { useState, useRef, useEffect } from "react";
import { BoxProps, Stack, Text, Input, IconButton } from "@chakra-ui/react";
import { CheckIcon, AddIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { IntentMessage } from "../IntentMessage";
import { colorSystem } from "../../theme";

interface FlowMessageProps extends BoxProps {
  message: FlowMessageUnion; // message to render
  isCurrentlyEditing?: boolean; // flag to check if it's currently in editing stage
  onSave?: (key: string, message: string) => void; // callback when the `message` is saved
  onEdit?: () => void; // callback when the `message` enters edit state
  stepName?: string;
  onOptionSave?: (index: number, value: string) => void;
  onOptionAdd?: (value: string) => void;
  onOptionDelete?: (value: string) => void;
}

export const FlowMessage = ({
  message,
  isCurrentlyEditing = false,
  stepName = "",
  onOptionSave,
  onOptionAdd,
  onOptionDelete,
  ...props
}: FlowMessageProps) => {
  // Rendering plain text message
  const [editingOptionIndex, setEditingOptionIndex] = useState<number>(-1);
  const [editingOptionValue, setEditingOptionValue] = useState<string>("");
  const [isAddingNewOption, setIsAddingNewOption] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState("");
  const [hoveredOptionIndex, setHoveredOptionIndex] = useState<number>(-1);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const saveButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        saveButtonRef.current &&
        !saveButtonRef.current.contains(event.target as Node)
      ) {
        // Clicked outside both input and save button
        setEditingOptionIndex(-1);
        setEditingOptionValue("");
      }
    }
  
    if (editingOptionIndex !== -1) {
      document.addEventListener("mousedown", handleClickOutside);
    }
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editingOptionIndex]);

  const handleOptionEdit = (index: number, currentValue: string) => {
    setEditingOptionIndex(index);
    setEditingOptionValue(currentValue);
  };

  const handleOptionSaveLocal = async (index: number) => {
    if (!editingOptionValue.trim()) {
      setEditingOptionIndex(-1);
      return;
    }
    onOptionSave?.(index, editingOptionValue);
    setEditingOptionIndex(-1);
    setEditingOptionValue("");
  };

  const handleAddNewOption = () => {
    if (!newOptionValue.trim()) {
      setIsAddingNewOption(false);
      return;
    }
    onOptionAdd?.(newOptionValue);
    setIsAddingNewOption(false);
    setNewOptionValue("");
  };

  const handleAddOptionKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleAddNewOption();
    }
  };

  const handleCancelAdd = () => {
    setIsAddingNewOption(false);
    setNewOptionValue("");
  };

  const handleDeleteOption = (option: string | { title: string }) => {
    const value = typeof option === 'string' ? option : option.title;
    onOptionDelete?.(value);
    setEditingOptionIndex(-1);
  };

  if (typeof message === "string") {
    return (
      <IntentMessage
        sender="bot"
        message={message}
        canEdit={true}
        onSave={(message) => props.onSave?.("", message)}
        isEditing={isCurrentlyEditing}
        setIsEditing={(_) => props.onEdit?.()}
      />
    );
  }

  // Rendering interactive message
  if (message.type === "button" || message.type === "list") {
    // TODO: edit buttons
    return (
      <Stack>
        <IntentMessage
          sender="bot"
          message={message.body}
          canEdit={true}
          onSave={(message) => props.onSave?.(".body", message)}
          isEditing={isCurrentlyEditing}
          setIsEditing={(_) => props.onEdit?.()}
        />
        <Stack
          w="min-content"
          px={4}
          direction={message.options.length < 3 ? "row" : "column"}
        >
          {typeof message.options === "string" ? (
            <Text>{message.options}</Text>
          ) : (
            <>
              {message.options.map((option, idx) => (
                <Stack 
                  key={idx} 
                  direction="row" 
                  align="center" 
                  spacing={2}
                  onMouseEnter={() => setHoveredOptionIndex(idx)}
                  onMouseLeave={() => setHoveredOptionIndex(-1)}
                >
                  {editingOptionIndex === idx ? (
                    <>
                      <Input
                        ref={inputRef}
                        w="min-content"
                        value={editingOptionValue}
                        onChange={(e) =>  setEditingOptionValue(e.target.value)}
                        autoFocus
                        size="sm"
                        maxLength={20}
                      />
                      <IconButton
                        ref={saveButtonRef}
                        aria-label="Save option"
                        icon={<CheckIcon />}
                        size="sm"
                        onClick={() => handleOptionSaveLocal(idx)}
                      />
                    </>
                  ) : (
                    <>
                      <Text
                        px={4}
                        py={2}
                        bg={colorSystem.surfacePurple}
                        w="max-content"
                        boxShadow="sm"
                        borderRadius="md"
                        cursor="pointer"
                        onClick={() => handleOptionEdit(idx, typeof option === "string" ? option : option.title)}
                      >
                        {typeof option === "string" ? option : option.title}
                      </Text>
                      {hoveredOptionIndex === idx && (
                        <IconButton
                          aria-label="Delete option"
                          icon={<DeleteIcon />}
                          size="sm"
                          colorScheme="red"
                          variant="ghost"
                          onClick={() => handleDeleteOption(option)}
                        />
                      )}
                    </>
                  )}
                </Stack>
              ))}
              {isAddingNewOption ? (
                <Stack direction="row" align="center" spacing={2}>
                  <Input
                    w="min-content"
                    value={newOptionValue}
                    onChange={(e) => setNewOptionValue(e.target.value)}
                    onKeyDown={handleAddOptionKeyDown}
                    autoFocus
                    size="sm"
                    placeholder="New option..."
                    maxLength={20}
                  />
                  <IconButton
                    aria-label="Save new option"
                    icon={<CheckIcon />}
                    size="sm"
                    colorScheme="green"
                    onClick={handleAddNewOption}
                  />
                  <IconButton
                    aria-label="Cancel"
                    icon={<CloseIcon />}
                    size="sm"
                    colorScheme="red"
                    variant="ghost"
                    onClick={handleCancelAdd}
                  />
                </Stack>
              ) : (message.options.length < 10 && (
                <IconButton
                  w="min-content"
                  aria-label="Add"
                  icon={<AddIcon />}
                  colorScheme="blue"
                  variant="outline"
                  size="sm"
                  onClick={() => setIsAddingNewOption(true)}
                />
              ))}
            </>
          )}
        </Stack>
      </Stack>
    );
  }

  //   TODO: Render and Edit Media
  return <></>;
};
