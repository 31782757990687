import axios from "./axiosInstance";
import config from "../config";

// Define the interface for the request parameters
interface ITemplateRequest {
  flowId: string; // flowId to be sent in headers
  token: string;  // Authentication token
}

// Define the interface for the response data (adjust based on the API response structure)
interface ITemplateResponse {
  source_number: string;
  gupshup_password: string;
  gupshup_userid: string;
  api_response: any;
  // Define the structure of the response data here
  status: string;
  data?: Array<{ id: number; name: string }>;
}

// Function to call the /dashboard/templates API
export const getTemplates = async ({
  flowId,
  token,
}: ITemplateRequest): Promise<ITemplateResponse> => {
  const response = await axios.get<ITemplateResponse>(
    `${config.url}/dashboard/templates`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        flowId: flowId,
      },
      withCredentials: true,
    }
  );

  return response.data;
};
