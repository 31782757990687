import axios from "../api/axiosInstance";
import config from "../config";
import { IFlowRequest } from "../models";

const token = localStorage.getItem("token")

const apiURL = "https://analytics.workhack.io";
const apiRoot = "v2/analytics";

type Dict = { [key: string]: any };

type NumDict = { [key: string]: number };

type StatDict = { [key: string]: NumericStats };

interface NumericStats {
  count: number;
  sum: number;
  mean: number;
  min: number;
  max: number;
  square_difference_from_mean: number;
  variance: number;
}

export interface FlowDropTurn {
  _id: number;
  user: number;
  users: string[];
}

export interface MessageBasicSummary {
  metadata: Dict;
  // "Count of the messages."
  count: number;
  // Count of the message types. Split based on sender.
  type_count: NumDict;
  // "Count of the message sender (either bot or user)."
  sender_count: NumDict;
  // Count of the status types, for every hour.
  status_count: NumDict;
  // Stats related to the length of the message text. Split based on sender.
  text_length_stats: StatDict;
}

export interface MessageReplySummary {
  metadata: Dict;
  //
  reply_time_stats: NumericStats;
}

export interface AnalyticsConfig {
  //
  first_message_timestamp: number;

  campaigns: {
    [campaignId: string]: {
      name: string;
      campaign_id: string;
      count: number;
    };
  };
}

export interface DashboardMetrics {
  user_data: {
    live_users_aggregate: number;
    live_users: {
      count: number;
      timestamp: number | string;
    }[];
  };
  invitation_summary: {
    summary: {
      Delivered: number;
      Sent: number;
      Failed: number;
    };
    timestamp: string;
  }[];
}

interface DashboardMetricsRequest extends IFlowRequest {
  startTime: number;
  endTime: number;
}

const splitStream = (separator: string) => {
  let buffer = "";

  return new TransformStream({
    transform(chunk, controller) {
      try {
        buffer += chunk;
        const parts = buffer.split(separator);
        parts.slice(0, -1).forEach((part) => controller.enqueue(part));
        buffer = parts[parts.length - 1];
      } catch (error) {
        console.log(error);
      }
    },
    flush(controller) {
      if (buffer) controller.enqueue(buffer);
    },
  });
};

const jsonParser = () =>
  new TransformStream({
    transform(chunk, controller) {
      try {
        let json = JSON.parse(chunk);
        if (typeof json == "object") {
          controller.enqueue(json);
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

export const getEngineHeaders = () => {
  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Authorization", "Bearer " + process.env.REACT_APP_ENGINE_API);
  return headers;
};

export const getMessageSummary = async (request: {
  flowId: string;
  from: number;
  to?: number;
}) => {
  const { flowId, from, to } = request;

  const response = await fetch(
    `${apiURL}/messages/basic/summary?flow_id=${flowId}&from_time=${parseInt(
      `${from}`
    )}`,
    {
      headers: getEngineHeaders(),
      method: "GET",
    }
  ).catch((_) => {
    console.log(_);
  });

  if (response) {
    if (response.status > 200) return null;

    const stream = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream("\n"))
      .pipeThrough(jsonParser());

    return stream;
  }

  return null;
};

export const getMessageReplySummary = async (request: {
  flowId: string;
  campaignId: string;
}) => {
  const { flowId, campaignId } = request;

  const response = await fetch(
    `${apiURL}/messages/replies/summary?flow_id=${flowId}&campaign_id=${campaignId}`,
    {
      headers: getEngineHeaders(),
      method: "GET",
    }
  ).catch((_) => {
    console.log(_);
  });

  if (response) {
    if (response.status > 200) return null;

    const stream = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream("\n"))
      .pipeThrough(jsonParser());

    return stream;
  }

  return null;
};

export const getAnalyticsConfig = async ({
  flowId,
  token,
}: IFlowRequest): Promise<AnalyticsConfig> => {
  const response = await axios.get<AnalyticsConfig>(
    `${config.url}/${apiRoot}/config/${flowId}`,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;
};

export const getFlowDrop = async ({
  flowId,
  token,
}: IFlowRequest): Promise<FlowDropTurn[]> => {
  const response = await axios.get<FlowDropTurn[]>(
    `${config.url}/${apiRoot}/dropoff/${flowId}`,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;
};

export const getDashboardMetrics = async ({
  flowId,
  startTime,
  endTime,
  token,
  abortController,
}: DashboardMetricsRequest) => {
  const response = await axios.post<DashboardMetrics>(
    `${config.url}/${apiRoot}/dashboard_metrics/${flowId}`,
    {
      start_date: startTime,
      end_date: endTime,
    },
    {
      signal: abortController?.signal,
      headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;
};
