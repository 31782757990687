import axios from "../api/axiosInstance";
import config from '../config'
import { ConversationComponent } from "../pages/Flow";
import { getEngineHeaders } from "./analytics";

const apiURL = "https://engine.workhack.io";
// const apiURL = "http://localhost:8000";
const apiRoot = "playground";

export interface Playground {
  goal: string;
  constraints: string;
  components: ConversationComponent[];
}

const getAPIHeaders = () => {
  const headers = getEngineHeaders();
  return Object.fromEntries(headers.entries());
};

export const getFlowPlayground = async (flowId: string) => {
  const response = await axios.get<Playground>(
    `${config.url}/v2/flow/playground/${flowId}`
  );

  return response.data;
};

export const generateContextFromComponent = async (
  request: ConversationComponent
) => {
  const response = await axios.post<string[]>(
    `${apiURL}/${apiRoot}/extract/context/`,
    request,
    { headers: getAPIHeaders(),
      withCredentials: true
     }
  );

  return response.data;
};
