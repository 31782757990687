import axios from "../api/axiosInstance";
import config from "../config";
import { v4 as uuidv4 } from 'uuid';

const apiRoot = "v2/golive";


// Define the types here
export interface BotRecords {
  id: string;
  data: {
    [key: string]: any;
  };
}

export interface AllBots {
  flow_id: string;
  bot_name: string;
  bot_type: string;
  bot_source: string;
  status: string;
  assigned: number;
  candidates: number
  assigned_companies: string[];  // Add this line
}

// Fetch data from the API
export const getBotsData = async () => {
  const response = await axios.get(`${config.url}/${apiRoot}/all_bots`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true
  });
  return response.data;
};

export const transformBotsToUserRecords = (bots: AllBots[]): BotRecords[] => {
  return bots.map((bot, index) => ({
    id: `${bot.flow_id}_${bot.bot_name}_${index}_${uuidv4()}`,  // Combine multiple fields and add a UUID
    data: {
      "Bot Name": bot.bot_name,
      "Flow Id": bot.flow_id,
      "Type": bot.bot_type,
      "Source": bot.bot_source,
      "Status": bot.status,
      "Assigned To": {
        count: bot.assigned,
        companies: bot.assigned_companies
      },
      "Candidates": bot.candidates,
    }
  }));
};

// Updated types for the config_source_data API response
export interface FlowsAndCompanies {
  flows: string[];
  organizations: string[];
}

export interface ConfigSourceDataResponse {
  bots: FlowsAndCompanies[];
}

// Fetch data from the API
export const getConfigSourceData = async (): Promise<ConfigSourceDataResponse> => {
  const response = await axios.get(`${config.url}/v2/golive/config_source_data`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true
  });
  return response.data;
};

// Updated interface
export interface organization {
  id: number; // Changed to number based on your API response
  name: string;
}

export interface organizationsData {
  OrganizationData: organization[]; // Adjusted to match API response
}

export const getOrganizations = async (): Promise<organizationsData> => {
  const response = await axios.get(`${config.url}/${apiRoot}/getOrgs`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true
  });
  return response.data;
};

export interface ExportStep {
  step_name: string;
  answer_field: string;
  intent_type: string;
  label: string;
  isSelected: boolean;
  response_values?: { [key: string]: string }; // Add this line
}


export interface ExportValuesResponse {
  steps: ExportStep[];
}

export const getExportValues = async (flowId: string): Promise<ExportValuesResponse> => {
  const response = await axios.get(`${config.url}/${apiRoot}/get_steps_data`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true,
    params: {
      flow_id: flowId,
    },
  });
  return response.data;
};

export interface Users {
  id: number;
  name: string;
}

export interface UsersResponse {
  usersData: Users[];
}

export const getUsers = async (organizationId: number): Promise<UsersResponse> => {
  const response = await axios.get(`${config.url}/${apiRoot}/get_users`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true,
    params: { organization_id: organizationId },
  });
  return response.data;
};

export interface ExistingSources {
  source_ids: string[];
}

export const getExistingSources = async (): Promise<ExistingSources> => {
  const response = await axios.get(`${config.url}/${apiRoot}/all_sources`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true,
  });
  return response.data;
};



export const goLiveData = async (data: any) => {
  try {
    const response = await axios.post(`${config.url}/${apiRoot}/save_golive_data`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // Server responded with a status other than 2xx
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error request data:", error.request);
    } else {
      // Something else happened
      console.error("Error message:", error.message);
    }
    throw error;
  }
};

export const createNewUser = async (data: any) => {
  try {
    const response = await axios.post(`${config.url}/${apiRoot}/create_user`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
      console.error("Error response headers:", error.response.headers);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Error request data:", error.request);
    } else {
      // Something else happened
      console.error("Error message:", error.message);
    }
    throw error;
  }
}