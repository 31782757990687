import React, { useState } from "react";
import {
    Box,
    Heading,
    Text,
    Divider,
    HStack,
    Stack,
    VStack,
    useToast,
    Select,
    Circle,
    RadioGroup, Radio



} from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { RiFeedbackFill } from "react-icons/ri";
import { BsClockFill } from "react-icons/bs";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import { IoCalendarNumber } from "react-icons/io5";
import { FaHandshakeSimple } from "react-icons/fa6";
import { BiLoaderAlt } from "react-icons/bi";
import dayjs from 'dayjs';
import config from "../../config";
import { useStoreState } from "../../hooks/store";
import isoweek from 'dayjs/plugin/isoWeek';


type ScheduleDataType = {
    [key: string]: [string, string, string, string , string];
};


const user = JSON.parse(localStorage.getItem('user'));










const ScheduledInterviews = () => {

    const toast = useToast();
    const today = dayjs()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [candidateData, setCandidateData] = useState(null);
    const [scheduleData, setScheduleData] = useState<ScheduleDataType>({});
    const [filterResult, setFilterResult] = useState<ScheduleDataType>({});
    const startOfWeek = today.startOf('week').add(1, 'day');
    const [selectedDate, setSelectedDate] = useState(today.format('dddd, MMMM D YYYY'));
    const [isLoading, setIsLoading] = useState(true);
    const [convoValue, setConvoId] = useState('')
    const flows = useStoreState((state) => state.flows);
    const [selectedFlow, setSelectedFlow] = useState('');
    const [sourceId, setSource] = useState('')

    // console.log('Beofer Usestate Flow ID', localStorage.getItem('selected_flow_id'));




    console.log('Schedule Data',scheduleData);
    

    // console.log('After Flow  ID Vicky -->',flowId);

    dayjs.extend(isoweek);

    const getWeekdays = () => {
        let weekdays = [];
        let day = dayjs();

        while (weekdays.length < 7) {
            if (day.day() !== 0 && day.day() !== 6) { // Avoid Sunday (0) and Saturday (6)
                weekdays.push(day.format('dddd, MMMM D YYYY'));
            }
            day = day.add(1, 'day'); // Move to the next day
        }

        return weekdays;
    };
    
    const weekDates = getWeekdays();




    const handleBookedInterviewAPIRequest = async (flowId, userId, format, date?) => {
        try {

            const requestData = {
                flowId: flowId,
                userId: userId,
                format: format,
                date: date

            }


            const response = await fetch(`${config.url}/get_booked_interview_slot`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                return {}

            }
            const resp = await response.json()
            const transformedData = resp.reduce((acc: ScheduleDataType, item: any) => {
                const key = Object.keys(item)[0];
                acc[key] = item[key];
                return acc;
            }, {});
            return transformedData
        } catch (error) {
            console.log('Something Wrong', error);

            toast(
                {
                    "title": "Something Wrong",
                    "status": "error"
                }
            )
            return {}

        }
    }

    const handleInterviewedDetailAPI = async (convoId, id) => {

        const requestData = {
            'id': id,
            'flowId': selectedFlow,
            'conversationId': convoId,
        }

        try {

            const response = await fetch(`${config.url}/get_Interviewed_detail`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                return false
            }

            const resp = await response.json();
            return resp

        } catch (error) {

            console.error(error);
            return false

        }
    }

    const handleBookedTimeSlot = async (flow_Id) => {


        if (!flow_Id) {

            return toast(
                {
                    "title": "Flow Never Selected",
                    "status": "error"
                }
            )
        }

        const resp = await handleBookedInterviewAPIRequest(flow_Id, user.email, "less_or_equal", dayjs(selectedDate).format('DD-MM-YYYY'))

        console.log("API Response in Frontend:", resp);

        if (resp && Object.keys(resp).length > 0) {
            setScheduleData(resp)
            toast(
                {
                    "title": "Data Updated",
                    "status": "success"
                }
            )
        } else {
            console.log("No valid data returned from API");
            setFilterResult({}); // Reset filterResult or show an error message
        }

    }

    const handleDateFormat = (param) => {
        const date = dayjs(param)
        return date.format('dddd D MMMM YYYY');
    }

    const handleCandidateClick = async (index) => {
        setIsLoading(true);
        // console.log('Index',index);
        // console.log('schedule', scheduleData[index]);

        const convoId = scheduleData[index][3];
        setConvoId(convoId);
        console.log('Convo ID',convoId);
        try {

            const resp = await handleInterviewedDetailAPI(convoId, index);
            // console.log('Interview Details ',resp);            
            if (resp) {
                setCandidateData(resp.data);
                setIsModalOpen(true);
            }
        } catch (error) {
            console.error("Failed to fetch interview details:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDateChange = async (event) => {
        const date = event.target.value;
        setSelectedDate(date)
        const resp = await handleBookedInterviewAPIRequest(selectedFlow, user.email, "equal", dayjs(date).format('DD-MM-YYYY'));
        // console.log('API Resp',resp);
        if (resp && Object.keys(resp).length > 0) {
            // Only set filterResult if the response is not empty and is a valid object
            setFilterResult(resp);
            toast(
                {
                    "title": "Slot Updated",
                    "status": "success"
                }
            )
        } else {
            console.log("No valid data returned from API");
            setFilterResult({}); // Reset filterResult or show an error message
        }
    };


    const returnRequiredDateFormat = (date: string) => {
        return dayjs(
            `${date.split('-')[1]}-${date.split('-')[0]}-${date.split('-')[2]}`, "DD-MM-YYYY"
        ).startOf('day')
    }

    const handleSelectChange = (event) => {
        const { value } = event.target;
        setSelectedFlow(value);
        handleBookedTimeSlot(value)
    };


    return (
        <Stack className="flex px-6 h-full overflow-hidden ">

            <VStack alignItems="flex-end" spacing={4} w="full" >
                <HStack spacing={4} alignItems="center" w="full" justify="flex-end">
                    <Box w="80">
                        <Select placeholder="Select Bot Flow" w="full" variant="filled"

                            onChange={handleSelectChange}
                            value={selectedFlow}>
                            {Object.keys(flows).length > 0 ? (
                                Object.entries(flows).map(([key, flow]) => (
                                    <option key={key}>{key}</option>
                                ))
                            ) : (
                                <option disabled>No flows available</option>
                            )}
                        </Select>
                    </Box>
                </HStack>

                <HStack justify="space-between" w="full" >
                    <Box py={2}>
                        <Heading py={1} size="md">Scheduled Interview</Heading>
                        <Text>Today's Insights, This Week's Schedule!</Text>
                    </Box>

                    <Box py={2} textAlign="left">
                        <Select size="md" variant="filled" onChange={handleDateChange} value={selectedDate}>
                            {weekDates.map((date, index) => (
                                <option key={index} value={date}>{date}</option>
                            ))}
                        </Select>
                    </Box>
                </HStack>

            </VStack>

            <Divider py={2} />

            <HStack spacing={8} align="stretch" className="p-4 w-full rounded-lg">
                <VStack spacing={4} className="flex-1" align="stretch">
                    <Box className="border p-4 rounded-lg bg-gray-50 flex-1">
                        <Box className="bg-white border rounded-lg shadow-md p-4 mb-4">
                            <Text fontSize="md" fontWeight="medium">
                                Scheduled Status
                            </Text>
                        </Box>
                        {
                            Object.entries(scheduleData).filter(([id, [userName, date]]) => {
                                const parsedDate = returnRequiredDateFormat(date);
                                const isToday = parsedDate.isSame(dayjs(), 'day');
                                return isToday ? parsedDate.isBefore(dayjs()) : parsedDate.isBefore(dayjs(), 'day');
                            }).length === 0 ? (
                                <Box className="p-4 rounded-lg w-full self-start">
                                    <Text textAlign="center" color="gray.500">You have no previous interviews scheduled.</Text>
                                </Box>
                            ) : (
                                Object.entries(scheduleData)
                                    .filter(([id, [userName, date, time]]) => {
                                        const [startTime] = time.split(' - ');
                                        // console.log(returnRequiredDateFormat(date));                                   
                                        const fullDateTimeString = `${date.split('-')[1]}-${date.split('-')[0]}-${date.split('-')[2]} ${startTime}`;
                                        // console.log('Full date time string',fullDateTimeString);

                                        const parsedDateTime = dayjs(fullDateTimeString);
                                        // console.log('is Date',parsedDateTime);
                                        // console.log('is Day Brefore',dayjs(fullDateTimeString).isBefore(dayjs()));

                                        return parsedDateTime.isBefore(dayjs());
                                    })
                                    .map(([id, [userName, date, time, status]], index) => (
                                        <Box
                                            key={index}
                                            className="border p-4 rounded-lg bg-white w-full hover:shadow-md self-start hover:cursor-pointer"
                                            onClick={() => {
                                                // console.log('Username',userName);
                                                console.log('Source' , scheduleData[id][4]);
                                                
                                                setSource(scheduleData[id][4])
                                                handleCandidateClick(id)
                                                
                                            }}
                                            mb={2}
                                        >
                                            <HStack alignItems="center" spacing={2} justifyContent="space-between">
                                                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" color={"#9B7EBD"}>
                                                    <HStack spacing={1} alignItems="center">
                                                        <FaUserAlt size="12px" color="gray.500" />
                                                        <Text fontSize="xs" color="gray.500" fontWeight="medium" pl={1}>
                                                            {userName}
                                                        </Text>
                                                    </HStack>
                                                    <HStack spacing={1} alignItems="center">
                                                        <BsClockFill size="12px" color="gray.500" />
                                                        <Text fontSize="xs" color="gray.500" fontWeight="medium" pl={1}>
                                                            {handleDateFormat(returnRequiredDateFormat(date))}
                                                        </Text>
                                                    </HStack>
                                                    <Box display="flex" alignItems="center" pl={2} mr={2}>
                                                        {status ? (
                                                            <Text fontSize="xs" color="transparent" fontWeight="medium" pl={1} ml={1}>
                                                                On Hold
                                                            </Text>
                                                        ) : (
                                                            <Text fontSize="xs" color="transparent" fontWeight="medium" pl={1} ml={1}>
                                                                On Hold
                                                            </Text>
                                                        )}
                                                    </Box>
                                                    <IoIosArrowUp />
                                                </Box>
                                            </HStack>
                                        </Box>
                                    ))
                            )}
                    </Box>
                </VStack>

                <Box className="border rounded-lg p-4 bg-gray-50 flex-1">
                    <Box className="bg-white border rounded-lg shadow-md p-4 mb-4">
                        <Text fontSize="md" fontWeight="medium" color="black" className="mb-1">
                            Weekly Schedule
                        </Text>
                        <Text fontSize="xs" color="gray.500" pl={1}>
                            {selectedDate}
                        </Text>
                    </Box>
                    <VStack spacing={4} className="mt-2 w-full">
                        {(() => {
                            const filteredSchedules = Object.entries(filterResult).filter(([id, [userName, date, time]]) => {
                                const selectedDateTime = dayjs(selectedDate, "dddd, MMMM D YYYY").startOf('day');
                                const scheduleDateTime = returnRequiredDateFormat(date)
                                return scheduleDateTime.isSame(selectedDateTime, 'day');
                            });

                            // Display message if no future schedules are found for the selected date
                            return filteredSchedules.length === 0 ? (
                                <Box className="p-4 rounded-lg w-full self-start">
                                    <Text textAlign="center" color="gray.500">Currently, no available schedules are found.</Text>
                                </Box>
                            ) : (
                                filteredSchedules.map(([id, [userName, date, time]], index) => (
                                    <Box key={index} className="border p-4 rounded-lg bg-white w-full hover:shadow-md self-start" color={"#9B7EBD"}>
                                        <Text fontSize="sm" fontWeight="medium" color="#605678" className="mb-1">
                                            {handleDateFormat(returnRequiredDateFormat(date))}
                                        </Text>
                                        <HStack alignItems="center" spacing={6}>
                                            <Box display="flex" alignItems="center">
                                                <FaUserAlt size="12px" color="gray.500" />
                                                <Text fontSize="xs" color="gray.500" fontWeight="medium" pl={1} ml={1}>
                                                    {userName}
                                                </Text>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <BsClockFill size="12px" color="gray.500" />
                                                <Text fontSize="xs" color="gray.500" fontWeight="medium" pl={1} ml={1}>
                                                    {time}
                                                </Text>
                                            </Box>
                                        </HStack>
                                    </Box>
                                ))
                            );
                        })()}
                    </VStack>

                </Box>
            </HStack>

            <CandidateInfoModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                candidate={candidateData}
                isLoading={isLoading}
                convoId={convoValue}
                flowId={selectedFlow}
                source_id={sourceId}
                
            />
        </Stack>
    );

}

const CandidateInfoModal = ({ isOpen, onClose, candidate, isLoading, convoId, flowId,source_id}) => {


    console.log('Checking Source', source_id);
    
    

    const toast = useToast();
    const [selectedDate, setDate] = useState(null);
    const [remarkValue, setRemarkValue] = useState('');
    const [ctcValue, setCTCValue] = useState('');
    const [statusValue, setStatusValue] = useState('Offer Accepted');
    const [isCtcFocused, setCTCFocused] = useState(false);
    const [isRemarkFocused, setRemarkFocused] = useState(false);
    const [isDateFocused, setDateFocused] = useState(false);
    const [saveloading, setSaveLoading] = useState(false);
    const value = "#1E3E62"

    const [processRating, setProcessRating] = useState(3);
    const [customerRating, setCustomerRating] = useState(3);
    const [commercialRating, setCommercialRating] = useState(3);
    const [recommendation, setRecommendation] = useState("Good");


    if (!isOpen) return null;

    const handleDateChange = (date) => setDate(date);

    const handlRemark = (e) => setRemarkValue(e.target.value);

    const handleOfferStatus = (e) => setStatusValue(e.target.value);

    const handleCtcOffer = (e) => {
        let value = e.target.value;
        value = value.replace(/,/g, '');
        if (!isNaN(value) && value !== '') {
            const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            setCTCValue(formattedValue);
        } else {
            setCTCValue(value);
        }
    };

    const saveCandidateInformation = async (convoId) => {

        if (!ctcValue) {
            toast({
                title: 'CTC Offer Value Missing',
                status: 'error',
                duration: 3000, // Optional: specify duration
                isClosable: true, // Optional: allow closing the toast
            });

            return
        }

        if (!selectedDate) {
            toast({
                title: 'Date of Joining Missing',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });

            return
        }

        if (!statusValue) {
            toast({
                title: 'Status Value Missing',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });

            return
        }

        const requestData = {
            'flowId': flowId,
            'conversationId': convoId,
            'ctcoffered': ctcValue,
            'date_of_join': selectedDate,
            'offer_status': statusValue,
            'recommendation' : recommendation,
            "ratings" : {"Process Excellence" : processRating ,
                         "Customer Centricity" : customerRating , 
                         "Commercial Acumen"  : commercialRating},
            'source' : source_id,
            'remark': remarkValue,
        }   


        try {

            const response = await fetch(`${config.url}/save_offer_details`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                toast(
                    {
                        "title": "Something Wrong",
                        "status": "error"
                    }
                )
                return
            }

            toast(
                {
                    "title": "Information Updated",
                    "status": "success"
                }
            )

            onClose();
            setCTCValue('')
            setDate('')
            setStatusValue('')
            setRemarkValue('')
        } catch (error) {

            toast(
                {
                    "title": error,
                    "status": "error"
                }
            )

        }




    }

    const sendInviteAPI = async () => {

        const requestData = {
            "flow_id": localStorage.getItem('selected_flow_id'),
            "source_id": localStorage.getItem('source'),
            "invitations": [{ "conversation_id": candidate.number, "name": candidate.name }],
            "data": {
                "referred-by": "{.name}",
                "referred-by-phone": "{.id}",
                "level": 1,
                "line1_invite": "This is a demo"
            },
            "send_invite_messages": true,
            "delete_previous_conversation": true,
        }

        try {
            const response = await fetch(`${config.engine}/invite`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_ENGINE_API}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                toast(
                    {
                        "title": "Something Wrong",
                        "status": "error"
                    }
                )
                return
            }


        } catch (error) {


            toast(
                {
                    "title": `Error :- ${error}`,
                    "status": "error"
                }
            )

        }

    }

    const handleSave = async () => {
        setSaveLoading(true);
        await saveCandidateInformation(convoId);
        if (statusValue && statusValue === "Offer Accepted") {
            await sendInviteAPI()
        }
        setSaveLoading(false);
    };


    const categories = [
        { label: "Process Excellence", rating: processRating, setRating: setProcessRating },
        { label: "Customer Centricity", rating: customerRating, setRating: setCustomerRating },
        { label: "Commercial Acumen", rating: commercialRating, setRating: setCommercialRating },
    ];

    return (
        <HStack className="fixed inset-0 z-50 flex items-start justify-center bg-black bg-opacity-50 ">


            {isLoading ? (
                <div className="flex items-center gap-2">
                    <BiLoaderAlt className="animate-spin text-gray-500" size={32} />
                    <p>Loading...</p>
                </div>
            ) : (
                <VStack className="bg-white rounded-lg shadow-lg w-3/5 p-4" height="600px" overflowY="auto" overflowX="hidden"  >
                    <HStack justify="space-between" width="full">
                        <Box>
                            <Text className="text-md font-bold text-[#3B1E54]">Candidate Information</Text>
                        </Box>
                        <Box onClick={onClose} className="cursor-pointer">
                            <IoClose size="20px" />
                        </Box>
                    </HStack>

                    <Divider px={2} />

                    <Box className="w-full p-4">

                        <Box className="flex mb-2 ">

                            <Box className="w-1/2 flex items-center justify-start">
                                <Text mr={2} className="text-sm">
                                    Candidate Name:
                                </Text>
                                <Text style={{ color: value }} fontWeight="medium">
                                    {candidate.candidateName ? candidate.candidateName : 'N/A'}
                                </Text>
                            </Box>


                            <Box className="w-1/2 flex items-center justify-start">
                                <Text mr={2} className="text-sm">
                                    Email:
                                </Text>
                                <Text style={{ color: value }} fontWeight="medium">
                                    {candidate.email ? candidate.email : 'N/A'}
                                </Text>
                            </Box>

                        </Box>

                        <Box className="flex mb-2">

                            <Box className="w-1/2 flex items-center justify-start">
                                <Text mr={2} className="text-sm">
                                    Applicant ID:
                                </Text>
                                <Text style={{ color: value }} fontWeight="medium">
                                    {candidate.applicantId ? candidate.applicantId : 'N/A'}
                                </Text>
                            </Box>


                            <Box className="w-1/2 flex items-center justify-start">
                                <Text mr={2} className="text-sm">
                                    Number:
                                </Text>
                                <Text style={{ color: value }} fontWeight="medium">
                                    {candidate.number ? candidate.number : 'N/A'}
                                </Text>
                            </Box>
                        </Box>

                        <Box className="flex mb-2">
                            <Box className="w-1/2 flex items-center justify-start">
                                <Text mr={2} className="text-sm">
                                    Current CTC:
                                </Text>
                                <Text style={{ color: value }} fontWeight="medium">
                                    {candidate?.current_ctc ? `₹ ${candidate.current_ctc.toLocaleString()}` : "N/A"}
                                </Text>
                            </Box>
                        </Box>


                    </Box>


                    <Divider px={2} />
                    <Box w="full" ml={2}>
                        <Text className="text-md font-bold text-[#3B1E54]">
                            Ratings
                        </Text>
                    </Box>


                    <VStack w="full" spacing={4} align="start" px={10}>
                        {categories.map(({ label, rating, setRating }) => (
                            <HStack key={label} w="full" spacing={6} align="center">
                                {/* Consistent width for labels to align numbers vertically */}
                                <Text fontSize="sm" fontWeight="normal" minW="140px">
                                    {label}
                                </Text>

                                {/* Custom Rating Buttons */}
                                <HStack spacing={2}>
                                    {[1, 2, 3, 4, 5].map((num) => (
                                        <Circle
                                            key={num}
                                            size="30px"
                                            border="1px solid"
                                            borderColor="purple.700"
                                            bg={rating === num ? "purple.700" : "transparent"}
                                            color={rating === num ? "white" : "purple.700"}
                                            fontWeight="normal"
                                            cursor="pointer"
                                            onClick={() => setRating(num)}
                                            transition="0.2s"
                                            _hover={{ bg: "purple.500", color: "white" }}
                                        >
                                            {num}
                                        </Circle>
                                    ))}
                                </HStack>
                            </HStack>
                        ))}
                    </VStack>





                    <VStack w="full" align="start" spacing={3}>
                        <Divider px={2} />

                        <Box w="full" ml={2}>
                            <Text fontSize="md" fontWeight="bold" color="#3B1E54">
                                Recommendation
                            </Text>
                        </Box>

                        <RadioGroup defaultValue="Good" onChange={setRecommendation}>
                            <Stack direction="column" spacing={4} ml={12}>
                                {[
                                    { label: "Good, recommended to hire", value: "Good" },
                                    { label: "Average, recommended to hire", value: "Average" },
                                    { label: "Not recommended to hire", value: "Not Recommended" },
                                ].map((option) => (
                                    <HStack key={option.value} w="full" justify="space-between">
                                        <Text fontSize="sm">{option.label}</Text>
                                        <Radio value={option.value} />
                                    </HStack>
                                ))}
                            </Stack>
                        </RadioGroup>

                    </VStack>

                    {/* <Divider px={2} /> */}
                    {/* <Box w="full" ml={2}>
                        <Text className="text-md font-bold text-[#3B1E54]">
                            Offer Range
                        </Text>
                    </Box> */}

                    {/* <Divider px={2} />

                    <Box className="w-full p-4">
                        <Box className="flex mb-2">
                            <Box className="w-1/2 flex items-center justify-start">
                                <Text className="text-sm mr-2">
                                    Job Code:
                                </Text>
                                <Text style={{ color: value }} fontWeight="medium">
                                    {candidate.jobCode ? candidate.jobCode : 'N/A'}
                                </Text>
                            </Box>
                            <Box className="w-1/2 flex items-center justify-start">
                                <Text className="text-sm mr-2"> 
                                    Min Salary:
                                </Text>
                                <Text style={{ color: value }} fontWeight="medium">
                                    {candidate.minSal ? `₹ ${candidate.minSal.toLocaleString()}` : 'N/A'}
                                </Text>
                            </Box>
                        </Box>

                        <Box className="flex mb-2">
                            <Box className="w-1/2 flex items-center justify-start">
                                <Text className="text-sm mr-2"> 
                                    Max Salary:
                                </Text>
                                <Text style={{ color: value }} fontWeight="medium">
                                    {candidate.maxSal ? `₹ ${candidate.maxSal.toLocaleString()}` : 'N/A'}
                                </Text>
                            </Box>
                            <Box className="w-1/2 flex items-center justify-start">
                                <Text className="text-sm mr-2">
                                    Hike:
                                </Text>

                                {candidate.hike ? (
                                    <Badge bg={"#B6FFA1"} textColor={"#117554"} variant="subtle" px={2} py={1} borderRadius="md">
                                        {candidate.hike}%
                                    </Badge>
                                ) :
                                    <Text style={{ color: value }} fontWeight="medium">
                                        N/A
                                    </Text>

                                }

                            </Box>
                        </Box>
                    </Box> */}



                    {/* <Divider px={2} />
                    <Box w="full" ml={2}>
                        <Text className="text-md font-bold text-[#3B1E54]">
                            Ideal Offer
                        </Text>
                    </Box>
                    <Divider px={2} />

                    <Box className="w-full p-4">
                       
                        <Box className="flex mb-2">
                            <Box className="w-1/2 flex items-center justify-start">
                                <Text className="text-sm mr-2">
                                    Ideal CTC:
                                </Text>
                                <Text style={{ color: value }} fontWeight="medium">
                                    {candidate.ectc ? `₹ ${candidate.ectc.toLocaleString()}` : "N/A"}
                                </Text>

                            </Box>


                            <Box className="w-1/2 flex items-center justify-start">
                                <Text className="text-sm mr-2"> 
                                    Range Status:
                                </Text>
                                <Text>
                                    <span
                                        className={`inline-flex items-center px-2 py-2 text-xs font-bold leading-none ${(() => {
                                          
                                            if (
                                                candidate.ectc === undefined ||
                                                candidate.maxSal === undefined ||
                                                isNaN(candidate.ectc) ||
                                                isNaN(candidate.maxSal)
                                            ) {
                                                return 'bg-gray-200 text-gray-500'; 
                                            }
                                            const idealCTC = candidate.ectc + (candidate.ectc * (candidate.hike / 100 || 0));
                                            return idealCTC <= candidate.maxSal ? 'bg-green-200 text-green-800' : 'bg-red-200 text-[#F95454]';
                                        })()} rounded-md`}
                                    >
                                        {(() => {
                                            if (
                                                candidate.ectc === undefined ||
                                                candidate.maxSal === undefined ||
                                                isNaN(candidate.ectc) ||
                                                isNaN(candidate.maxSal)
                                            ) {
                                                return "N/A";
                                            }

                                            const idealCTC = candidate.ectc + (candidate.ectc * (candidate.hike / 100 || 0));
                                            return idealCTC <= candidate.maxSal ? "Within Range" : "Out of Range";
                                        })()}
                                    </span>
                                </Text>


                            </Box>
                        </Box>
                    </Box> */}


                    <Divider px={2} />
                    <Box w="full" ml={2}>
                        <Text className="text-md font-bold text-[#3B1E54]">
                            Offer Details
                        </Text>
                    </Box>
                    <Divider px={2} />

                    <Box className="w-full p-4">


                        <Box className="w-full p-1 flex flex-row justify-between mb-2">

                            <Box className="flex w-2/5">
                                <Box className="relative w-full">
                                    <Box className="flex items-center border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease-in-out focus-within:border-slate-400 hover:border-slate-300 shadow-sm focus-within:shadow">
                                        <Box className="mr-2 text-slate-400 text-sm">
                                            {isCtcFocused && <FaIndianRupeeSign />}
                                        </Box>
                                        <input
                                            type="text"
                                            value={ctcValue}
                                            className="peer w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm focus:outline-none pl-1"
                                            onFocus={() => setCTCFocused(true)}
                                            onBlur={() => {
                                                if (ctcValue === '') setCTCFocused(false);
                                            }}
                                            onChange={handleCtcOffer}
                                        />
                                    </Box>
                                    <label className={`absolute cursor-text bg-white px-1 left-2.5 transition-all transform origin-left text-slate-400 text-sm
                    ${isCtcFocused || ctcValue ? '-top-2 text-xs scale-90' : 'top-2.5'}`}>
                                        CTC Offered
                                    </label>
                                </Box>
                            </Box>


                            <Box className="flex w-2/5">
                                <Box className="relative w-full">
                                    <Box className="flex items-center border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease-in-out focus-within:border-slate-400 hover:border-slate-300 shadow-sm focus-within:shadow">
                                        {isDateFocused && (
                                            <Box className="mr-2">
                                                <IoCalendarNumber className="text-xl text-slate-400" />
                                            </Box>
                                        )}
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            className="peer w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm focus:outline-none pl-1"
                                            popperPlacement="bottom"
                                            onFocus={() => setDateFocused(true)}
                                            onBlur={() => {
                                                if (!selectedDate) setDateFocused(false);
                                            }}
                                        />
                                    </Box>
                                    <label className={`absolute cursor-text bg-white px-1 left-2.5 transition-all transform origin-left text-slate-400
                                        ${isDateFocused || selectedDate ? '-top-2 text-xs scale-90' : 'top-2.5 text-sm'}`}>
                                        Date of Join
                                    </label>
                                </Box>
                            </Box>
                        </Box>

                        {/* Offer Status and Remarks Section */}
                        <Box className="w-full p-1 flex flex-row justify-between">

                            {/* Offer Status */}
                            <Box className="w-2/5 flex">
                                <Box className="relative w-full">
                                    <Box className="flex items-center border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease-in-out focus-within:border-slate-400 hover:border-slate-300 shadow-sm focus-within:shadow">
                                        <Box className="mr-2">
                                            <FaHandshakeSimple className="text-xl text-slate-400" />
                                        </Box>
                                        <select
                                            className="w-full bg-transparent text-slate-700 text-sm focus:outline-none"
                                            value={statusValue}
                                            onChange={handleOfferStatus}
                                        >
                                            <option value="Offer Accepted">Offer Accepted</option>
                                            <option value="Offer Rejected">Offer Rejected</option>
                                            <option value="On Hold">On Hold</option>
                                        </select>
                                    </Box>
                                </Box>
                            </Box>

                            {/* Remarks */}
                            <Box className="flex w-2/5">
                                <Box className="relative w-full">
                                    <input
                                        type="text"
                                        onFocus={() => setRemarkFocused(true)}
                                        onBlur={() => {
                                            if (remarkValue === '') setRemarkFocused(false);
                                        }}
                                        className="peer w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease-in-out focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow pl-10"
                                        placeholder=" "
                                        value={remarkValue}
                                        onChange={handlRemark}
                                    />
                                    <label className={`absolute cursor-text bg-white px-1 left-2.5 transition-all transform origin-left text-slate-400 
                    ${isRemarkFocused || remarkValue ? '-top-2 text-xs scale-90' : 'top-2.5 text-sm'}`}>
                                        Remarks
                                    </label>
                                    {isRemarkFocused && (
                                        <RiFeedbackFill className="absolute left-3 top-1/2 transform -translate-y-1/2 text-xl text-slate-400" />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>



                    <Box className="flex justify-center">
                        <Box
                            className={`px-2 py-2 border text-[#347928] rounded-md bg-[#B6FFA1] hover:cursor-pointer hover:shadow-md 
                            ${saveloading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            onClick={!saveloading ? handleSave : undefined}
                        >
                            <Text fontWeight="medium">
                                {saveloading ? 'Saving...' : 'Save'}
                            </Text>
                        </Box>
                    </Box>

                </VStack>
            )}
        </HStack>
    );

};

export default ScheduledInterviews