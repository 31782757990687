import React from "react";
import {
  AspectRatio,
  Box,
  BoxProps,
  CircularProgress,
  Image,
  Input,
  ResponsiveValue,
  Stack,
} from "@chakra-ui/react";
import Plyr from "plyr-react";

import { MediaType } from "../models/intent";
import { TemplateComponentVariant } from "../constants";
import { Button, WHButtonProps } from "./Button";
import { EditIcon } from "../constants/icons";

interface SelectableImageProps extends Omit<BoxProps, "src"> {
  /**
   * The aspect ratio of the Box. Common values are:
   *
   * `21/9`, `16/9`, `9/16`, `4/3`, `1.85/1`
   */
  type?: MediaType;
  hidden?: boolean;
  loading?: boolean;
  src?: string;
  ratio?: ResponsiveValue<number>;
  variant?: TemplateComponentVariant;
  buttonProps?: WHButtonProps;
  onMediaUpload?: (type: MediaType, file?: File) => void;
}

export const SelectableImage = ({
  ratio = 4 / 3,
  type: mediaType = "image",
  variant = "edit",
  ...props
}: SelectableImageProps) => {
  const videoPlayer =
    mediaType !== "image" ? (
      <Plyr
        source={{
          type: "video" as const,
          sources: [
            {
              src: props.src,
            },
          ],
        }}
        controls={false}
        options={{
          controls: [],
        }}
      />
    ) : null;

  return (
    <Box position="relative" hidden={props.hidden} {...props}>
      <AspectRatio ratio={ratio} borderRadius="md">
        {mediaType === "image" ? (
          <Image
            fallbackSrc="https://admin-api.assesshub.com/sample.jpeg"
            alignSelf="center"
            inset={0}
            src={props.src}
            borderRadius="md"
          />
        ) : (
          <div>{videoPlayer}</div>
        )}
      </AspectRatio>
      {props.loading && (
        <Stack
          borderRadius="md"
          bg="blackAlpha.400"
          bgBlendMode="darken"
          justify="center"
          position="absolute"
          top={0}
          left={0}
          width="full"
          height="full"
        >
          <CircularProgress alignSelf="center" isIndeterminate />
        </Stack>
      )}
      {variant === "edit" && (
        <Button
          leftIcon={<EditIcon />}
          position="absolute"
          top={5}
          right={5}
          {...props.buttonProps}
        >
          Change Image
        </Button>
      )}
      <Input
        hidden={props.loading || variant === "preview"}
        position="absolute"
        top={0}
        left={0}
        width="full"
        height="full"
        type="file"
        aria-hidden="true"
        accept="image/*, audio/*, video/mp4, video/3gpp"
        size="sm"
        opacity={0}
        onChange={(event) => {
          if (event.currentTarget.files.length !== 0) {
            const uploadedFile = event.currentTarget.files[0];

            if (uploadedFile.type.includes("video"))
              return props.onMediaUpload("video", uploadedFile);

            return props.onMediaUpload("image", uploadedFile);
          }
        }}
      />
    </Box>
  );
};
