import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTemplates } from '../../api/templates';
import {
  Heading,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Badge,
  VStack,
  HStack,
  Icon,
  Container,
  useToast,
  Divider,
  Grid,
  Tooltip,
  Card,
  CardBody,
  Tabs,
  TabList,
  Tab,
  ButtonGroup,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Skeleton,
  Stack,
  useColorModeValue,
  Flex
} from '@chakra-ui/react';
import {
  FiCalendar,
  FiGlobe,
  FiTag,
  FiCheckCircle,
  FiMessageCircle,
  FiMousePointer,
  FiStar
} from 'react-icons/fi';

const StatusBadge = ({ status }) => {
  const statusProps = {
    ENABLED: { colorScheme: 'green', label: 'Enabled' },
    DISABLED: { colorScheme: 'red', label: 'Disabled' },
    UNKNOWN: { colorScheme: 'gray', label: 'Unknown' }
  }[status] || { colorScheme: 'gray', label: status };

  return (
    <Badge colorScheme={statusProps.colorScheme} borderRadius="full" px={3} py={1}>
      {statusProps.label}
    </Badge>
  );
};

const InfoRow = ({ icon, label, value, tooltip }) => {
  const content = (
    <HStack spacing={3} align="flex-start">
      <Icon as={icon} color="gray.500" boxSize={5} mt={1} />
      <VStack align="start" spacing={0}>
        <Text fontWeight="medium" color="gray.600" fontSize="sm">
          {label}
        </Text>
        <Text>{value || 'N/A'}</Text>
      </VStack>
    </HStack>
  );

  return tooltip ? (
    <Tooltip label={tooltip} placement="top">
      {content}
    </Tooltip>
  ) : content;
};

const MessageBody = ({ body }) => {

  const highlightedBody = body?.replace(
    /{{(\d+)}}/g,
    (match: any, num: any) => `[Variable ${num}]`
  ) || '';

  return (
    <VStack align="stretch" spacing={2}>
      <HStack>
        <Icon as={FiMessageCircle} color="gray.500" boxSize={5} />
        <Text fontSize="lg" color="gray.500" fontWeight="semibold">
          Message Template
        </Text>
      </HStack>
      <Card variant="outline">
        <CardBody>
          <Text whiteSpace="pre-wrap">{highlightedBody}</Text>
        </CardBody>
      </Card>
    </VStack>
  );
};

const UserDataBox = ({ userData }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box
      bg={bgColor}
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="lg"
      p={4}
      shadow="sm"
      minW="400px"
    >
      <HStack spacing={6} align="start">
        <Box>
          <Text color="gray.500" fontSize="sm">User ID</Text>
          <Text fontWeight="medium">{userData.userId || 'N/A'}</Text>
        </Box>
        <Divider orientation='vertical' height='40px' />  
        <Box>
          <Text color="gray.500" fontSize="sm">Password</Text>
          <Text fontWeight="medium">{userData.password || 'N/A'}</Text>
        </Box>
        <Divider orientation='vertical' height='40px' />
        <Box>
          <Text color="gray.500" fontSize="sm">WABA Number</Text>
          <Text fontWeight="medium">{userData.waba_no || 'N/A'}</Text>
        </Box>
        <Divider orientation='vertical' height='40px' />
        <Box>
          <Text color="gray.500" fontSize="sm">Analytics</Text>
          <Text
            as="a"
            href={`https://${userData.link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text color="blue.400" _hover={{ textDecoration: "underline", cursor: "pointer" }}  cursor="pointer">
              View Dashboard
            </Text>
          </Text>
        </Box>

      </HStack>
    </Box>
  );
};

export const Templates = () => {
  const { id: flowId } = useParams();
  const [templates, setTemplates] = useState([]);
  const [userData, setUserData] = useState({
    userId: '',
    password: '',
    waba_no: '',
    link:'unify.smsgupshup.com/WhatsApp/Analytics/'
  });
  const [totalCount, setTotalCount] = useState({
    UTILITY: '',
    MARKETING: '',
    ENABLED_UTILITY: '',
    DISABLED_UTILITY: '',
    ENABLED_MARKETING: '',
    DISABLED_MARKETING: ''
  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('UTILITY');
  const [selectedStatus, setSelectedStatus] = useState('ENABLED');
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('gray.200', 'gray.700');
  const headerBg = useColorModeValue('gray.200', 'gray.800');
  const toast = useToast();

  useEffect(() => {
    const fetchTemplates = async () => {
      if (!flowId) {
        console.error('Flow ID is missing');
        return;
      }
  
      try {
        setIsLoading(true);
        const token = localStorage.getItem('token');
        if (!token) {
          toast({
            title: "Authentication Error",
            description: "Please log in again",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
  
        const response = await getTemplates({ flowId, token });
        if (response?.api_response) {
          console.log('Templates:', response.api_response.data);
          setTemplates(response.api_response.data);
          setUserData(prev => ({
            ...prev,
            userId: response.gupshup_userid || '',
            password: response.gupshup_password || '',
            waba_no: response.source_number || ''
          }));
  
          // Count templates based on category and status
          const categoryCounts = { UTILITY: 0, MARKETING: 0 };
          const statusCounts = { ENABLED_UTILITY: 0, DISABLED_UTILITY: 0, ENABLED_MARKETING: 0, DISABLED_MARKETING: 0 };
  
          response.api_response.data.forEach((template: any) => {
            if (template.category === "UTILITY") {
              categoryCounts.UTILITY++;
              if (template.status === "ENABLED") statusCounts.ENABLED_UTILITY++;
              if (template.status === "DISABLED") statusCounts.DISABLED_UTILITY++;
            }
            if (template.category === "MARKETING") {
              categoryCounts.MARKETING++;
              if (template.status === "ENABLED") statusCounts.ENABLED_MARKETING++;
              if (template.status === "DISABLED") statusCounts.DISABLED_MARKETING++;
            }
          });
  
          setTotalCount({
            UTILITY: categoryCounts.UTILITY.toString(),
            MARKETING: categoryCounts.MARKETING.toString(),
            ENABLED_UTILITY: statusCounts.ENABLED_UTILITY.toString(),
            DISABLED_UTILITY: statusCounts.DISABLED_UTILITY.toString(),
            ENABLED_MARKETING: statusCounts.ENABLED_MARKETING.toString(),
            DISABLED_MARKETING: statusCounts.DISABLED_MARKETING.toString(),
          });
  
        } else {
          console.log('Error fetching templates:', response[0].detail);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTemplates();
  }, [flowId, toast]);

  const handleRowClick = (template:any) => {
    setSelectedTemplate(template);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTemplate(null);
  };

  const formatDate = (dateString:any) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const filteredTemplates = templates.filter(template => 
    template.category === selectedCategory && 
    template.status === selectedStatus
  );

  return (
    <Container maxW="container.xl" py={8}>
      {/* <Heading size="lg" mb={6}>
        Templates
      </Heading> */}

      <Box w="full" mb={6}>
        <Flex justify="space-between" align="center">
          <Heading size="lg">Templates</Heading>
          <UserDataBox userData={userData} />
        </Flex>
      </Box>

      <Tabs variant="outline" colorScheme="blue" mb={6}>
        <Tabs>
        <TabList>
          <Tab onClick={() => setSelectedCategory('UTILITY')}>Utility ({totalCount.UTILITY})</Tab>
          <Tab onClick={() => setSelectedCategory('MARKETING')}>Marketing ({totalCount.MARKETING})</Tab>
        </TabList>
        </Tabs>
      </Tabs>

      <ButtonGroup mb={6}>
        <Button
          colorScheme={selectedStatus === 'ENABLED' ? 'green' : 'gray'}
          onClick={() => setSelectedStatus('ENABLED')}
        >
          Enabled ({selectedCategory === 'UTILITY' ? totalCount.ENABLED_UTILITY : totalCount.ENABLED_MARKETING})
        </Button>
        <Button
          colorScheme={selectedStatus === 'DISABLED' ? 'red' : 'gray'}
          onClick={() => setSelectedStatus('DISABLED')}
        >
          Disabled ({selectedCategory === 'UTILITY' ? totalCount.DISABLED_UTILITY : totalCount.DISABLED_MARKETING})
        </Button>
      </ButtonGroup>

      {isLoading ? (
        <Stack spacing={4}>
          {[...Array(5)].map((_, i) => (
            <Skeleton key={i} height="50px" />
          ))}
        </Stack>
      ) : (
        <Box overflowX="auto">
          <Table variant="simple" borderWidth="1px" borderColor={borderColor}>
            <Thead bg={headerBg}>
              <Tr>
                <Th fontSize="md" fontWeight="normal">Name</Th>
                <Th fontSize="md" fontWeight="normal">Type</Th>
                <Th fontSize="md" fontWeight="normal">Language</Th>
                <Th fontSize="md" fontWeight="normal">Status</Th>
                <Th fontSize="md" fontWeight="normal">Created</Th>
                <Th fontSize="md" fontWeight="normal">Updated</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredTemplates.length > 0 ? (
                filteredTemplates.map(template => (
                  <Tr
                    key={template.id}
                    onClick={() => handleRowClick(template)}
                    cursor="pointer"
                    _hover={{ bg: hoverBg }}
                    transition="background-color 0.2s"
                  >
                    <Td>{template.name.charAt(0).toUpperCase() + template.name.slice(1)}</Td>
                    <Td>{template.type}</Td>
                    <Td>{template.language}</Td>
                    <Td><StatusBadge status={template.status} /></Td>
                    <Td>{formatDate(template.creation_time)}</Td>
                    <Td>{formatDate(template.updation_time)}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={6} textAlign="center" py={6} color="gray.500">
                    No Templates Found
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      )}

      <Modal isOpen={isModalOpen} onClose={closeModal} size="2xl">
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent>
          <ModalHeader borderBottomWidth="1px">
            <HStack spacing={4}>
              <Text fontSize="xl" fontWeight="bold">
                {selectedTemplate?.name.charAt(0).toUpperCase() + selectedTemplate?.name.slice(1)}
              </Text>
              <StatusBadge status={selectedTemplate?.status} />
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          
          <ModalBody py={6}>
            <VStack spacing={4} align="stretch">
              {/* <Box>
                <Text fontSize="lg" fontWeight="semibold" mb={4}>
                  Template Details
                </Text>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                  gap={6}
                >
                  <InfoRow
                    icon={FiTag}
                    label="Category"
                    value={selectedTemplate?.category} tooltip={undefined}/>
                  <InfoRow
                    icon={FiGlobe}
                    label="Language"
                    value={selectedTemplate?.language} tooltip={undefined}/>
                  <InfoRow
                    icon={FiCheckCircle}
                    label="Type"
                    value={selectedTemplate?.type} tooltip={undefined}/>
                  <InfoRow
                    icon={FiMousePointer}
                    label="Button Type"
                    value={selectedTemplate?.button_type} tooltip={undefined}/>
                  <InfoRow
                    icon={FiStar}
                    label="Quality Score"
                    value={selectedTemplate?.quality_score}
                    tooltip={selectedTemplate?.quality_score === 'UNKNOWN' ? 'Quality score has not been calculated yet' : null}
                  />
                </Grid>
              </Box> */}

             {/*  <Divider borderColor="gray.300" />

              <Text fontSize="lg" fontWeight="semibold" mb={4}>
                Template Details
              </Text> */}

              <MessageBody body={selectedTemplate?.body}/>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Templates;