import axios from "axios";

// Global Axios response interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/session_expire";
    }
    return Promise.reject(error);
  }
);

export default axios;
