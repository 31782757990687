import React, { useState, useEffect } from "react";
import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  Input,
  FormErrorMessage,
  Stack,
  ButtonGroup,
  useToast,
  Heading,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Select,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import "react-phone-input-2/lib/high-res.css";

import { Button } from "../components/Button";
import { DashboardTemplate } from "../models/property";
import { useStoreActions, useStoreState } from "../hooks/store";
import { ExportIcon } from "../constants/icons";
import { CSVInput } from "../components/Input/Csv";
import { isValidPhoneNumber } from "../utils/countryCodes";
import { generateCSVExport } from "../utils";
import { useUploadFile } from "../mutations/root/useUploadFile";
import { useFeatureFlags } from "../data/flags/useFeatureFlags";
import { DISABLE_INTERNATIONAL_NO_IMPORT } from '../constants';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

const sanitizeInput = (input) => DOMPurify.sanitize(input);


const UserInviteRequestSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Name should only contain letters and spaces")
    .min(5, "Name must be at least 2 characters long")
    .max(100, "Name can't exceed 20 characters")
    .required("Name is required"),
  email: Yup.string()
    .email('Invalid email address')
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone is required"),
});


type SelectUsersProps = {
  flowId: string;
  template?: DashboardTemplate;
  onNext?: () => void;
  onPrevious?: () => void;
  onBulkImport?: (users: any[]) => void;
  setSelectedChannel?: (value: string) => void;
  selectedChannel?: string;
  subChannel?: string;
  setsubChannel?: (value: string) => void;
};


export const SelectNewUsers = ({
  flowId,
  onBulkImport,
  setSelectedChannel,
  selectedChannel = "Whatsapp Chatbot",
  subChannel,
  setsubChannel,
  ...props
}: SelectUsersProps) => {
  const navigate = useNavigate();
  const invitedUsers = useStoreState((state) => state.newUsers);
  const storeInvitedUsers = useStoreActions((actions) => actions.cacheNewUsers);
  const removeCachedUsers = useStoreActions((actions) => actions.clearNewUsers);
  const storeCampaignData = useStoreActions((actions) => actions.cacheCampaignData);
  const toastMessage = useToast();
  const uploadFileMutation = useUploadFile();

  const [users, setUsers] = useState(invitedUsers ?? []);
  const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState([]);
  const { data: flags } = useFeatureFlags(flowId);

  const [selectedValue, setSelectedValue] = useState(selectedChannel ?? '');

  

  const isFeatureEnabled = (feature) => {
    return (flags?.flags ?? []).includes(feature);
  };

  const uploadCSVFile = async (file) => {
    if (!file) return;

    try {
      let response = await uploadFileMutation.mutateAsync(file);
      if (response) {
        storeCampaignData({
          key: 'csv',
          value: response.url,
        });
      }
    } catch (error) {
      toastMessage({
        status: 'error',
        title: 'Upload Error',
        description: 'Failed to upload CSV file. Please try again.',
        position: 'bottom',
      });
    }
  };

  const storeSingleUser = (name, phone, email) => {
    const user = { Name: name, 'Phone Number': phone, Email: email };

    storeInvitedUsers([user]);
    setUsers([user]);

    toastMessage({
      status: 'success',
      description: `Saved user: ${name}`,
      position: 'bottom',
    });
  };



  const validateCSVData = (data) => {
    const invalidPhoneNumbers = [];
    const validPhoneNumbers = [];
  
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/; // Simple email validation regex
    const phoneNumberRegex = /^\d{12}$/;
  
    // Function to check for malicious content
    const containsMaliciousContent = (input) => {
      const maliciousPatterns = [
        /<script.*?>.*?<\/script>/i, // Detect script tags
        /onerror\s*=/i,             // Detect onerror attributes
        /javascript:/i,             // Detect JavaScript URLs
        /<img.*?>/i,                // Detect img tags
        /<.*?on\w+\s*=/i,           // Detect other on* attributes
      ];
      return maliciousPatterns.some((pattern) => pattern.test(input));
    };
  
    data.forEach((row, index) => {
      const sanitizedRow = { ...row };
      const rowErrors = [];
      const rowCorrections = [];
  
      Object.entries(row).forEach(([field, value]) => {
        if (!value || typeof value !== "string") return;
  
        if (containsMaliciousContent(value)) {
          rowErrors.push(`Field "${field}" contains malicious content.`);
          rowCorrections.push(`Remove any suspicious content in "${field}".`);
        }
  
        if (field === "Email" && !emailRegex.test(value)) {
          rowErrors.push(`Field "${field}" has an invalid email format.`);
          rowCorrections.push(`Enter a valid email in the format abc@example.com.`);
        }
  
        if (field === "Phone Number" && !phoneNumberRegex.test(value)) {
          rowErrors.push(`Field "${field}" has an invalid phone number format.`);
          rowCorrections.push(
            `Use a valid phone number (e.g., +1 9876543210).`
          );
        }
      });
  
      if (rowErrors.length > 0) {
        invalidPhoneNumbers.push({
          ...sanitizedRow,
          Errors: rowErrors,
          Corrections: rowCorrections,
        });
      } else {
        validPhoneNumbers.push(sanitizedRow);
      }
    });
  
    return { invalidPhoneNumbers, validPhoneNumbers };
  };
  

  const handleChannelChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    if (setSelectedChannel) {
      setSelectedChannel(newValue);
    }
    if (setsubChannel) {
      setsubChannel(""); // Reset sub-channel when channel changes
    }
  };

  return (
    <Stack justify="center" alignItems="start">
      <Heading className="text-left">Who?</Heading>
      <p className="text-left text-xl">Which users do you want to ping?</p>
      <main className="mt-8 w-full rounded-lg">
        <Tabs colorScheme="pink" variant="enclosed" isLazy>
          <TabList>
            <Tab onClick={() => removeCachedUsers()}>Bulk</Tab>
            <Tab onClick={() => removeCachedUsers()}>Single</Tab>
          </TabList>

          <TabPanels className="w-full">
            <TabPanel as={VStack} className="w-full" px={24} py={6}>
              {flowId == 'csb-cet' ||  flowId == 'csb-cet-v2' ? <Button
                leftIcon={<ExportIcon />}
                variant="tertiary"
                onClick={() => {
                  window.open("/assets/sample_csb.csv", "_blank");
                }}
              >
                Download sample file
              </Button> :( flowId == 'poonawala-hiring' ? <Button
                leftIcon={<ExportIcon />}
                variant="tertiary"
                onClick={() => {
                  window.open("/assets/sample_poonawala_hiring.csv", "_blank");
                }}
              >
                Download sample file
              </Button> : <Button
                leftIcon={<ExportIcon />}
                variant="tertiary"
                onClick={() => {
                  window.open("/assets/sample.csv", "_blank");
                }}
              >
                Download sample file
              </Button>)}

              {invalidPhoneNumbers.length > 0 && (
                <Alert
                  status="error"
                  variant="subtle"
                  flexDirection="row"
                  alignItems="self-start"
                  py={4}
                >
                  <AlertIcon mr={4} />
                  <Stack spacing={1}>
                    <AlertTitle>
                      Invalid phone numbers or emails found in the file
                    </AlertTitle>
                    <AlertDescription>
                      There are {invalidPhoneNumbers.length} record(s)
                      with invalid phone numbers or emails. Please correct them
                      and try again.<br/>
                      Please click on the below <b>Export</b> button to export CSV containing invalid numbers.
                    </AlertDescription>
                    <Button
                      alignSelf="start"
                      variant="danger"
                      action="delete"
                      onClick={() =>
                        generateCSVExport({
                          records: invalidPhoneNumbers,
                          filename: 'invalid_records.csv',
                        })
                      }
                    >
                      Export
                    </Button>
                  </Stack>
                </Alert>
              )}

              <CSVInput
                className="w-full rounded-xl"
                onChange={(file) => uploadCSVFile(file)}
                bulkInvite={true}
                onParse={(data, clearFile) => {
                  if (data.length === 0) {
                    onBulkImport?.([]);
                    storeInvitedUsers([]);
                    setInvalidPhoneNumbers([]);
                    return setUsers([]);
                  }

                  // if (isFeatureEnabled(DISABLE_INTERNATIONAL_NO_IMPORT)) {
                  //   alert('3')
                  //   onBulkImport?.(data);
                  //   storeInvitedUsers(data);
                  //   return setUsers(data);
                  // }
                  const { invalidPhoneNumbers, validPhoneNumbers } = validateCSVData(data);

                  setInvalidPhoneNumbers(invalidPhoneNumbers);

                  if (validPhoneNumbers.length > 0 && invalidPhoneNumbers.length === 0) {
                    onBulkImport?.(validPhoneNumbers);
                    storeInvitedUsers(validPhoneNumbers);
                    setUsers(validPhoneNumbers);
                  } else {
                    onBulkImport?.([]);
                    storeInvitedUsers([]);
                    setUsers([]);
                  }
                }}
              />
              <Stack spacing={4} w="full">
                <FormControl>
                  <Select
                    placeholder="Select a channel"
                    borderColor="gray.200"
                    backgroundColor="white"
                    value={selectedValue}
                    onChange={handleChannelChange}
                  >
                    <option value="Whatsapp Chatbot">Whatsapp Chatbot</option>
                    <option value="Web Chatbot">Web Chatbot</option>
                    <option value="Webwhatsapp Chatbot">Webwhatsapp Chatbot</option>
                    <option value="Slack Chatbot">Slack Chatbot</option>
                  </Select>
                </FormControl>
                {(selectedValue === "Web Chatbot" || selectedValue === "Webwhatsapp Chatbot") && (
                  <FormControl>
                    <Select
                      placeholder="Select Invitation Link"
                      borderColor="gray.200"
                      backgroundColor="white"
                      value={subChannel}
                      onChange={(e) => setsubChannel?.(e.target.value)}
                    >
                      <option value="Email Invitation">Email Invitation Link</option>
                      <option value="Whatsapp Invitation">Whatsapp Invitation Link</option>
                    </Select>
                  </FormControl>
                )}
              </Stack>
              
            </TabPanel>
            <TabPanel as={VStack} className="w-full" px={24} py={6}>
              <Formik
                initialValues={{ name: '', phone: '', email: '' }}
                onSubmit={async (values) => {
                  try {
                    storeSingleUser(
                      sanitizeInput(values.name),
                      sanitizeInput(values.phone),
                      sanitizeInput(values.email)
                    );
                    onBulkImport?.([]);
                  } catch (error) {
                    console.error("Error storing user:", error);
                  }
                }}
                validationSchema={UserInviteRequestSchema}
              >
                {({ handleSubmit, isSubmitting, resetForm, setFieldValue, values }) => (
                  <Stack w="full" spacing={4}>
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <Input
                            {...field}
                            placeholder="Name"
                            maxLength={1000}
                            minLength={5}
                            borderColor="gray.200"
                            backgroundColor="white"
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <Input
                            {...field}
                            placeholder="Email"
                            type="email"
                            borderColor="gray.200"
                            backgroundColor="white"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="phone">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.phone && form.touched.phone}
                        >
                          <PhoneInput
                            placeholder="Phone number"
                            country={"in"}
                            inputStyle={{
                              width: '100%',
                              borderColor: 'var(--chakra-colors-gray-200)',
                              backgroundColor: '#F8F9F9',
                              height: 'var(--chakra-sizes-10)',
                            }}
                            value={values.phone}
                            onChange={(value) => {
                              setFieldValue('phone', value);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.phone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Stack spacing={4} w="full">
                      <FormControl>
                        <Select
                          placeholder="Select a channel"
                          borderColor="gray.200"
                          backgroundColor="white"
                          value={selectedValue}
                          onChange={handleChannelChange}
                        >
                          <option value="Whatsapp Chatbot">Whatsapp Chatbot</option>
                          <option value="Web Chatbot">Web Chatbot</option>
                          <option value="Webwhatsapp Chatbot">Webwhatsapp Chatbot</option>
                        </Select>
                      </FormControl>
                      {(selectedValue === "Web Chatbot" || selectedValue === "Webwhatsapp Chatbot") && (
                        <FormControl>
                          <Select
                            placeholder="Select Invitation Link"
                            borderColor="gray.200"
                            backgroundColor="white"
                            value={subChannel}
                            onChange={(e) => setsubChannel?.(e.target.value)}
                          >
                            <option value="Email Invitation">Email Invitation Link</option>
                            <option value="Whatsapp Invitation">Whatsapp Invitation Link</option>
                          </Select>
                        </FormControl>
                      )}
                    </Stack>
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          storeInvitedUsers(users);
                          handleSubmit();
                        }}
                        isDisabled={isSubmitting}
                      >
                        Save
                      </Button>
                      <Button onClick={() => resetForm()} variant="tertiary">
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                )}
              </Formik>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </main>
    </Stack>
  );
};
