/**
 * Renders Default Conversation of the Flow
 */

import React, { useEffect, useState } from "react";
import { Stack, useToast } from "@chakra-ui/react";
import {
  editDefaultConversation,
  getDefaultConversation,
} from "../../api/engine";
import { useParams } from "react-router-dom";
import { FlowMessage } from "../../components/Conversation/FlowMessage";
import chatImage from "../../img/chat_bg.png";
import NotFound from "../../pages/NotFound";
import { useAuthToken } from "../../hooks/useAuthToken";
import Progress from "../../components/Feedback/Progress";

export const DefaultFlowConversation = () => {
  const { id: flowId } = useParams();
  const toastMessage = useToast();
  const { getAuthToken } = useAuthToken();

  const [loading, setLoading] = useState(false);
  const [flow, setFlow] = useState<Flow | null>({ steps: {} });
  const [currentEditingStep, setCurrentEditingStep] = useState<string>("");

  useEffect(() => {
    getDefaultFlow();
  }, []);

  const getDefaultFlow = async () => {
    const handleErrorResponse = () => {
      setLoading(false);
      toastMessage({ description: "Failed to fetch default conversation", status: "error" });
    };

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const flow = await getDefaultConversation({ flowId, token }).catch(handleErrorResponse);
      setLoading(false);
      if (flow) setFlow(flow);
    } catch (error) {
      handleErrorResponse();
    }
  };

  const updateFlowCopy = async (key: string, value: string) => {
    if (key === "" || value === "") return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const result = await editDefaultConversation({
        flowId,
        intentKey: `steps.${key}`,
        intentValue: value,
        operation: "edit_message", // Use "edit_message" for updating flow copy
        token,
      });
      setLoading(false);

      if (result) {
        getDefaultFlow();
        toastMessage({ description: "Updated the copy" });
      }
    } catch (error) {
      console.error("Error in updateFlowCopy:", error.response?.data || error);
      setLoading(false);
      toastMessage({ description: "Failed to update copy", status: "error" });
    }
  };

  const updateFlowOptions = async (stepName: string, optionIndex: number, value: string) => {
    if (!value.trim()) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token || !flowId) return;

      const intentKey = `steps.${stepName}.question.0.options.${optionIndex}`;
      const result = await editDefaultConversation({
        flowId,
        intentKey,
        intentValue: value,
        operation: "edit_option", // Use "edit_option" for updating an existing option
        token,
      });

      setLoading(false);

      if (result) {
        getDefaultFlow();
        toastMessage({ description: "Updated the option" });
      }
    } catch (error) {
      console.error("Error in updateFlowOptions:", error.response?.data || error);
      setLoading(false);
      toastMessage({ description: "Failed to update option", status: "error" });
    }
  };

  const addNewOption = async (stepName: string, value: string) => {
    if (!value.trim()) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token || !flowId) return;

      const intentKey = `steps.${stepName}.question.0.options`;
      const result = await editDefaultConversation({
        flowId,
        intentKey,
        intentValue: value,
        operation: "add_option", // Use "add_option" for adding a new option
        token,
      });

      setLoading(false);

      if (result) {
        getDefaultFlow();
        toastMessage({ description: "Added new option" });
      }
    } catch (error) {
      console.error("Error in addNewOption:", error.response?.data || error);
      setLoading(false);
      toastMessage({ description: "Failed to add option", status: "error" });
    }
  };

  const deleteFlowOption = async (stepName: string, value: string) => {
    if (!value.trim()) return;

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token || !flowId) return;

      const intentKey = `steps.${stepName}.question.0.options`;
      const result = await editDefaultConversation({
        flowId,
        intentKey,
        intentValue: value,
        operation: "delete_option", // Use "delete_option" for removing an option
        token,
      });

      setLoading(false);

      if (result) {
        getDefaultFlow();
        toastMessage({ description: "Deleted option" });
      }
    } catch (error) {
      console.error("Error in deleteFlowOption:", error.response?.data || error);
      setLoading(false);
      toastMessage({ description: "Failed to delete option", status: "error" });
    }
  };

  return (
    <Stack justify="center" align="center" maxH="100vh">
      <Stack
        w="container.lg"
        boxShadow="0px 4px 13px rgba(0, 0, 0, 0.07);"
        spacing={4}
        p={8}
        h="100vh"
        overflowY="scroll"
        style={{ background: `url(${chatImage}) #FAFBFC` }}
      >
        {loading && <Progress w="full" size="xs" />}
        <Stack hidden={Object.keys(flow.steps).length !== 0}>
          <NotFound title="No Conversation" subtitle="" />
        </Stack>
        {Object.keys(flow.steps).map((stepName, idx) => (
          <FlowStep
            key={idx}
            name={stepName}
            step={flow.steps[stepName]}
            isActive={currentEditingStep === stepName}
            onEdit={() => setCurrentEditingStep(stepName)}
            onChange={updateFlowCopy}
            onOptionSave={(optionIndex, value) => updateFlowOptions(stepName, optionIndex, value)}
            onOptionAdd={(value) => addNewOption(stepName, value)}
            onOptionDelete={(value) => deleteFlowOption(stepName, value)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

interface FlowStepProps {
  name: string;
  step: FlowIntentUnion;
  isActive?: boolean;
  onChange?: (key: string, value: string) => void;
  onEdit?: () => void;
  onOptionSave?: (optionIndex: number, value: string) => void;
  onOptionAdd?: (value: string) => void;
  onOptionDelete?: (value: string) => void;

}

const FlowStep = ({
  name,
  step,
  isActive = false,
  onOptionSave,
  onOptionAdd,
  onOptionDelete,
  ...props
}: FlowStepProps) => {
  const [currentActiveIndex, setCurrentActiveIndex] = useState(-1);

  const isEditing = (index: number) => isActive && currentActiveIndex === index;

  const handleEditing = (index: number) => {
    setCurrentActiveIndex(index);
    props.onEdit?.();
  };

  if (step.intent_type === "ask-definite") {
    if (!step.question) return <></>;

    if (typeof step.question === "string")
      return (
        <FlowMessage
          isCurrentlyEditing={isEditing(0)}
          message={step.question}
          stepName={name}
          onEdit={() => handleEditing(0)}
          onSave={(key, message) => {
            setCurrentActiveIndex(-1);
            props.onChange?.(`${name}.question${key}`, message);
          }}
          onOptionSave={onOptionSave}
          onOptionAdd={onOptionAdd}
          onOptionDelete={onOptionDelete}
        />
      );

    return (
      <Stack>
        {step.question.map((stepMessageUnion, index) => (
          <FlowMessage
            key={index}
            isCurrentlyEditing={isEditing(index)}
            message={stepMessageUnion}
            stepName={name}
            onEdit={() => handleEditing(index)}
            onSave={(key, message) => {
              setCurrentActiveIndex(-1);
              props.onChange?.(`${name}.question.${index}${key}`, message);
            }}
            onOptionSave={onOptionSave}
            onOptionAdd={onOptionAdd}
            onOptionDelete={onOptionDelete}
          />
        ))}
      </Stack>
    );
  }

  if (
    step.intent_type === "say" ||
    step.intent_type === "collect-attachments"
  ) {
    if (typeof step.text === "string")
      return (
        <FlowMessage
          isCurrentlyEditing={isEditing(0)}
          message={step.text}
          stepName={name}
          onEdit={() => handleEditing(0)}
          onSave={(key, message) => {
            setCurrentActiveIndex(-1);
            props.onChange?.(`${name}.text${key}`, message);
          }}
          onOptionSave={onOptionSave}
          onOptionAdd={onOptionAdd}
          onOptionDelete={onOptionDelete}
        />
      );

    return (
      <Stack>
        {step.text.map((stepMessageUnion, index) => (
          <FlowMessage
            key={index}
            isCurrentlyEditing={isEditing(index)}
            message={stepMessageUnion}
            stepName={name}
            onEdit={() => handleEditing(index)}
            onSave={(key, message) => {
              setCurrentActiveIndex(-1);
              props.onChange?.(`${name}.text.${index}${key}`, message);
            }}
            onOptionSave={onOptionSave}
            onOptionAdd={onOptionAdd}
            onOptionDelete={onOptionDelete}
          />
        ))}
      </Stack>
    );
  }

  if (step.intent_type === "ask-open" || step.intent_type === "gpt-generate") {
    if (typeof step.template === "string") {
      return (
        <FlowMessage
          isCurrentlyEditing={isEditing(0)}
          message={step.template}
          stepName={name}
          onEdit={() => handleEditing(0)}
          onSave={(key, message) => {
            setCurrentActiveIndex(-1);
          }}
          onOptionSave={onOptionSave}
          onOptionAdd={onOptionAdd}
          onOptionDelete={onOptionDelete}
        />
      );
    }

    return step.template.pre_prompt ? (
      <FlowMessage
        isCurrentlyEditing={isEditing(0)}
        message={step.template.pre_prompt}
        stepName={name}
        onEdit={() => handleEditing(0)}
        onSave={(key, message) => {
          setCurrentActiveIndex(-1);
          props.onChange?.(`${name}.template.pre_prompt`, message);
        }}
        onOptionSave={onOptionSave}
        onOptionAdd={onOptionAdd}
        onOptionDelete={onOptionDelete}
      />
    ) : (
      <></>
    );
  }

  return <></>;
};
