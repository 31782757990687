import React, { useEffect } from "react";
import { Box, Button, Center, Text, VStack, keyframes, Image  } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import emptyImageSrc from "../img/empty.svg";


const clearSessionData = () => {
  // Clear localStorage
  localStorage.clear();

  // Clear cookies
  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
  });

};

const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const SessionExpired = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("sessionStatus", "expired");
  }, []);

  const handleLogout = () => {
    clearSessionData();
    navigate("/login");
  };

  return (
    <Center height="100vh" bg="white">
      <VStack
        spacing={6}
        p={8}
        textAlign="center"
      >
          <Image animation={`${float} 3s ease-in-out infinite`} src={emptyImageSrc} />

        <Text fontSize="2xl" fontWeight="bold" color="gray.700">
          Oops! Your session has expired.
        </Text>
        <Text fontSize="lg" color="gray.700">
          Looks like you've been away for a while. Please log in again to continue.
        </Text>
        <Button colorScheme="purple" size="lg" onClick={handleLogout}>
          Go to Login
        </Button>
      </VStack>
    </Center>
  );
};

export default SessionExpired;
