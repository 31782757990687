import axios from "../api/axiosInstance";
import { FilterField, FilterQuery } from "../components/Filter/models";
import config from "../config";
import { IFlowRequest, PaginationConfig } from "../models";

const apiRoot = "v2/users";

export interface UserRecord {
  id: string;
  data: {
    [x: string]: any;
  };
}

interface UsersQueryResult {
  users: UserRecord[];
  last_document_index?: number;
}

export interface UserTabularData {
  table: {
    [x: string]: any;
  };
  summary: {
    [x: string]: any;
  };
  overview: {
    [x: string]: any;
  };
  test_results: {
    [x: string]: any;
  };
}
export interface SortGroup {
  key: string;
  order: "asc" | "desc";
}

export interface IUsersQueryRequest extends IFlowRequest {
  filters: FilterQuery[];
  pagination?: PaginationConfig;
  sort?: SortGroup[];
  abortController?: AbortController;
}

interface IEditUserRequest extends IFlowRequest {
  filters: FilterQuery[];
  action: "hide" | "update" | "clear";
  data?: {
    [dataPoint: string]: string;
  };
}
export interface UserTranscriptRequest extends IFlowRequest {
  conversationOId: string;
  pagination: PaginationConfig;
}

interface IUserRequest extends IFlowRequest {
  conversationId: string;
}

interface IUserProfileRequest extends IUserRequest {
  isLatest?: boolean;
}

interface UserTranscriptResult {
  transcript: MessageInDb[];
  last_document_index: number;
}

export const getUsers = async (
  request: IUsersQueryRequest
): Promise<UsersQueryResult> => {
  const response = await axios.post<UsersQueryResult>(
    `${config.url}/${apiRoot}/${request.flowId}`,
    {
      filters: request.filters,
      pagination: request.pagination,
      sort: request.sort,
    },
    {
      signal: request.abortController?.signal,
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true,
    }
  );

  return response.data;
};

/**
 *
 * @param flowId
 * @returns unique users count for the flow
 */
export const getUsersCount = async ({
  flowId,
  token,
  abortController,
}: IFlowRequest) => {

  let response = await axios.get<{ count: number }>(
    `${config.url}/${apiRoot}/count/${flowId}`,
    {
      signal: abortController?.signal,
      headers: {
          Authorization: `Bearer ${token}`,
        },
       withCredentials: true,
    }
  );

  if (response.status != 200)
    return Promise.reject(`fetching funnel analytics failed`);

  return response.data;
};

export const getUser = async ({
  flowId,
  conversationId,
  token,
}: IUserRequest): Promise<UserTabularData> => {
  const response = await axios.get<UserTabularData>(
    `${config.url}/${apiRoot}/user/${flowId}/${conversationId}`,
    {
      headers: {
          Authorization: `Bearer ${token}`,
        },
       withCredentials: true,
      params: {
        session_id: conversationId,
      }

    }
  );

  return response.data;
};

export const getFlowFilters = async ({
  flowId,
  token,
}: IFlowRequest): Promise<FilterField[]> => {
  const response = await axios.get<FilterField[]>(
    `${config.url}/${apiRoot}/filters/${flowId}`,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;
};

export const editUsers = async (request: IEditUserRequest) => {
  const response = await axios.post(
    `${config.url}/${apiRoot}/edit/${request.flowId}`,
    {
      filters: request.filters,
      action: request.action,
      data: request.data,
    },
    {
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true,
    }
  );

  return response.data;
};

export const getUserTranscript = async (request: UserTranscriptRequest) => {
  const response = await axios.post<UserTranscriptResult>(
    `${config.url}/${apiRoot}/transcript/${request.flowId}/${request.conversationOId}`,
    request.pagination,
    {
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true,
    }
  );

  return response.data;
};

export const getUserProfiling = async ({
  isLatest = false,
  ...request
}: IUserProfileRequest) => {
  const response = await axios.get<{
    fixed: { [attribute: string]: any };
    variable: { [attribute: string]: any };
  }>(
    `${config.url}/${apiRoot}/user_profiling/${request.flowId}/${request.conversationId}`,
    {
      params: {
        latest: isLatest,
      },
    }
  );

  return response.data;
};

export const selectionInvite = async (inviteData: any) => {
  const response = await axios.post(
    `${config.admin_url}/api/ah-bot-api/send-invite/${inviteData.flowId}`, inviteData,
    {
      headers: {
        Authorization: `Bearer ${inviteData.token}`,
      },
      withCredentials: true,
    }
  );

  return response.data;
};

export const updateNewUsers = async ({
  flowId,
  url,
}) => {
  const response = await axios.post(
    `${config.engine}/update_new_users/${flowId}`,
    {
      url: url,
    },
    {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ENGINE_API}`,
        }
    }
  );

  return response.data;
};