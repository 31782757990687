import React, { useState, useEffect, forwardRef, useRef } from "react";
import {
  Stack,
  HStack,
  Input,
  Text,
  StackProps,
  useToast,
} from "@chakra-ui/react";
import * as xlsx from "xlsx";

import { Button } from "../Button";
import { UploadIcon, CloseIcon } from "../../constants/icons";
import { parseCSV } from "../../utils";

interface CSVInputProps extends Omit<StackProps, "onChange"> {
  onChange?: (file: File) => void;
  bulkInvite?: boolean;
  onRemove?: () => void;

  // callback to parse the data
  onParse?: (data: any[], clearFile?: () => void) => void;
}

export const CSVInput = forwardRef<HTMLDivElement, CSVInputProps>(
  ({ onChange, bulkInvite, onRemove, onParse, ...props }, ref) => {
    const toast = useToast();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const extractFromCSV = async (file: File) => {
      if (!file) return;

      const type = file?.type ?? "";
      const reader = new FileReader();

      reader.onload = (evt) => {
        if (type === "text/csv") {
          let data = evt.target.result;
          let parseResult = parseCSV(`${data}`);
          onParse?.(parseResult.data, () => setFile(null));
        } else {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = xlsx.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = xlsx.utils.sheet_to_csv(ws, {
            rawNumbers: true,
          });
          /* Update state */
          let parseResult = parseCSV(data);
          onParse?.(parseResult.data, () => setFile(null));
        }
      };
      reader.readAsBinaryString(file);
    };

    const handleDrag = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }
    };

    // triggers when file is dropped
    const handleDrop = function (e: React.DragEvent) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);

      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const selectedFile = e.dataTransfer.files[0];

        // Validate file size and type
        if (selectedFile.size > 2 * 1024 * 1024) {
          toast({
            title: "File size too large",
            description: "File size should not exceed 2MB.",
            status: "warning",
          });
          return;
        }

        const allowedTypes = [
          "text/csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ];

        if (!allowedTypes.includes(selectedFile.type)) {
          toast({
            title: "Unsupported file type",
            description: "Please upload a valid CSV or XLSX file.",
            status: "error",
          });
          return;
        }

        setFile(selectedFile);
      }
    };

    useEffect(() => {
      const extractContents = () => {
        if (file) return extractFromCSV(file);
        return onParse?.([], () => setFile(null));
      };

      extractContents();
    }, [file]);

    return (
      <Stack ref={ref} {...props}>
        <label
          className={`flex justify-center w-full transition appearance-none cursor-pointer border-dashed border p-4 ${
            dragActive ? "border-blue-300" : ""
          }`}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <span className="flex flex-col items-center space-x-2 space-y-2">
            <UploadIcon size={24} />
            <span className="font-medium text-gray-600">
              Drop your file here or &nbsp;
              <span className="text-[#6941C6] underline">browse file</span>
            </span>
            <span className="text-xs text-gray-400">
              Only CSV/XLSX supported
              
              </span>
              {bulkInvite && <span className="text-sm text-gray-600">
  <b>Kindly Note:</b> Please enter mobile number as apostrophe (’) 91 followed by 10 digit mobile number in csv or excel format. <b>E.g:</b> ’919878987878
</span>}
          </span>

          <Input
            ref={inputRef}
            className="hidden"
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(event) => {
              const files = event.currentTarget.files;
              if (files && files.length > 0) {
                const selectedFile = files[0];

                // Validate file size and type
                if (selectedFile.size > 2 * 1024 * 1024) {
                  toast({
                    title: "File size too large",
                    description: "File size should not exceed 2MB.",
                    status: "warning",
                  });
                  return;
                }

                setFile(selectedFile);
                onChange?.(selectedFile);
              }
            }}
          />
        </label>

        {file && (
          <HStack justify="space-between" align="center">
            <span>
              <Text maxW="72" isTruncated>
                {file.name}
              </Text>
              <Text fontSize="xs" className="text-gray-600">
                ({(file.size / 1024).toPrecision(2)} KB)
              </Text>
            </span>
            <Button
              size="xs"
              leftIcon={<CloseIcon />}
              variant="tertiary"
              onClick={() => {
                setFile(null);
                if (inputRef.current) inputRef.current.value = null;
                onRemove?.();
              }}
            >
              Remove
            </Button>
          </HStack>
        )}
      </Stack>
    );
  }
);
