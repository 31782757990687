import axios from "../../../api/axiosInstance";
import React, { useState, useEffect } from "react";
import {
  HStack,
  SimpleGrid,
  SimpleGridProps,
  Stack,
  useToast,
  Text,
  Image,
  Input,
  Box,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

import emptyImageSrc from "../../../img/empty.svg";
import { DashboardTemplate } from "../../../models/property";
import { WhatsAppTemplate } from "../../../components/WhatsAppTemplate";
import { Button } from "../../../components/Button";
import { useStoreActions, useStoreState } from "../../../hooks/store";
import { BaseInviteLayout, InviteViewProps } from "./BaseLayout";
import { defaultWhatsappTemplate } from "../../../constants";
import { EditTemplate } from "./EditTemplate";
import { colorSystem } from "../../../theme";
import { useMessageTemplates } from "../../../data/flow/useMessageTemplates";
import ForeverProgress from "../../../components/Feedback/Progress";
import { getTimeAgo } from "../../../components/InfiniteTable";
import { Appbar } from "../../../components/Appbar";
import { SearchIcon, CloseIcon } from "../../../constants/icons";

interface SelectTemplateProps extends InviteViewProps, SimpleGridProps {
  sourceId?: string;
  onTemplateSelected?: (template: DashboardTemplate) => void;
  onTemplatesLoaded?: (templates: {
    [templateId: string]: DashboardTemplate;
  }) => void;
  navigation?: boolean;
}

export const SelectTemplate = ({
  flowId,
  sourceId,
  navigation = true,
  ...props
}: SelectTemplateProps) => {
  const toastMessage = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const cachedTemplate = useStoreState((state) => state.template);
  const saveTemplate = useStoreActions((actions) => actions.cacheTemplate);
  const [selectedTemplate, setSelectedTemplate] =
    useState<DashboardTemplate>(null);

  const { data, isLoading, isRefetching, error, refetch } = useMessageTemplates(
    {
      flowId,
      sourceId,
    }
  );

  useEffect(() => {
    props.onTemplateSelected?.(selectedTemplate);
  }, [selectedTemplate]);

  if (error) {
    if (axios.isAxiosError(error)) {
      console.log(`${error.code}`);
    }
    return (
      <Stack spacing={0} h="full" justify="center" align="center">
        <Image src={emptyImageSrc} />
        <Text className="text-base text-[#1D2939]">
          Failed to get templates at this moment
        </Text>
        <Button isLoading={isLoading || isRefetching} onClick={() => refetch()}>
          Try again
        </Button>
      </Stack>
    );
  }

  return (
    <BaseInviteLayout
      className="w-full"
      nextButton={{
        onClick: () => props.onNext?.(),
        isDisabled: cachedTemplate.name === defaultWhatsappTemplate.name,
      }}
      backButton={{ onClick: () => props.onBack?.() }}
      footer={navigation}
    >
      <Appbar
        p={0}
        showHeader={false}
        variant="sticky"
        boxProps={{ top: navigation ? -12 : 0 }}
      >
        <HStack w="full" align="center" justify="space-between" py={4}>
          <InputGroup size="sm" maxW="sm">
            <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
            <Input
              size="sm"
              autoFocus
              value={searchQuery}
              placeholder="Search by name"
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
            />
            <InputRightElement>
              {searchQuery.length !== 0 && (
                <CloseIcon onClick={() => setSearchQuery("")} />
              )}
            </InputRightElement>
          </InputGroup>
          {/* <HStack> */}
          {/* {data && data.last_updated && (
              <Text className="text-sm text-gray-400">
                Last Updated: {getTimeAgo(data.last_updated / 1000)}
              </Text>
            )} */}
          <Button isLoading={isRefetching} onClick={() => refetch()}>
            Refresh
          </Button>
          {/* </HStack> */}
        </HStack>
        {isLoading && <ForeverProgress />}
      </Appbar>
      <SimpleGrid
        className="w-full"
        gridTemplateColumns={`repeat(2, minmax(0, auto))`}
        gridTemplateRows="min-content auto"
        maxW="calc(100vw - 16rem)"
        maxHeight="100%"
        gridGap={4}
        {...props}
      >
        {data &&
          data.templates &&
          Object.keys(data.templates)
            .filter((templateId) =>
              data.templates[templateId].name.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((templateId, index) => {
              const template: DashboardTemplate = data.templates[templateId];

              return (
                <Box key={`${templateId}_${index}`}>
                  {cachedTemplate.name === template.name ? (
                    <EditTemplate flowId={flowId} />
                  ) : (
                    <WhatsAppTemplate
                      className={`bg-gray-50 self-center w-full p-2 h-fit border rounded-lg cursor-pointer`}
                      flowId={flowId}
                      key={template.name}
                      id={templateId}
                      onClick={() => {
                        const templateStatus = template.data?.["status"] ?? "";
                        if (templateStatus === "approved") {
                          saveTemplate(template);
                          return setSelectedTemplate(template);
                        }

                        toastMessage({
                          description: "Selected template is disabled",
                          status: "error",
                        });
                      }}
                      template={template}
                      border={
                        selectedTemplate === null
                          ? ""
                          : selectedTemplate.name === template.name
                          ? `1px solid ${colorSystem["textPurple"]}`
                          : ""
                      }
                    />
                  )}
                </Box>
              );
            })}
      </SimpleGrid>
    </BaseInviteLayout>
  );
};
