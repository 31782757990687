import axios from "../api/axiosInstance";
import config from "../config";

import { WidgetMessageInDb } from "../components/ChatWidget";
import { SOCKET_URL, SOCKET_USER, SOCKET_BOT } from "../constants";
import { IFlowRequest } from "../models/api";

const apiPrefix = "v2/engine";
const engineAPIPrefix = "engine/flags";

export interface KnowledgebaseFlags {
  // "Bot's main flow is support first and micro flows are triggered only with user requests.",
  is_this_support_first_bot: boolean;

  // "Transfer to agents if user sends media messages"
  enable_transfer_for_media_messages: boolean;

  // "Static message to send to user when we transfer to agent."
  static_agent_transfer_override_message?: string;

  // "Customize message to send to user sends message during out of office hours and we transfer to agent."
  enable_out_of_office_hours_custom_agent_transfer_message?: boolean;

  // "Starting and ending Out of office hours for the bot",
  out_of_office_hours: string[];

  // "Customize message to send to user when sent message during out of office hours"
  out_of_office_hours_custom_agent_transfer_message?: string;

  // "Threshold for bot to transfer to agent"
  search_threshold?: number;
}

export const getDefaultConversation = async ({
  flowId,
  token,
}: IFlowRequest) => {
  const response = await axios.get<Flow>(
    `${config.url}/${apiPrefix}/get_flow/${flowId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,

    }
  );

  return response.data as Flow;
};

export interface IEditFlowRequest {
  flowId: string;
  intentKey: string;
  intentValue: string;
  operation?: "edit_message" | "edit_option" | "add_option" | "delete_option"; // Updated operation types
  token: string;
}

export const editDefaultConversation = async ({
  flowId,
  intentKey,
  intentValue,
  operation = "edit_message",
  token,
}: IEditFlowRequest) => {
  const payload = {
    intent_key: intentKey,
    intent_value: intentValue,
    operation,
  };
  // console.log("Sending payload to edit_flow:", payload);

  const response = await axios.post(
    `${config.url}/${apiPrefix}/edit_flow/${flowId}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    }
  );

  return response.data;
};

// interface IEditConversationOptionsRequest extends IFlowRequest {
//   intentKey: string;
//   intentValue: string;
// }

// export const editDefaultConversationOptions = async ({
//   flowId,
//   intentKey,
//   intentValue,
//   token,
// }: IEditConversationOptionsRequest) => {
//   const response = await axios.post(
//     `${config.url}/${apiPrefix}/edit_flow_options/${flowId}`,
//     {
//       intent_key: intentKey,
//       intent_value: intentValue,
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       withCredentials: true,
//     }
//   );

//   return response.data;
// };

// interface IAddConversationOptionRequest extends IFlowRequest {
//   flowId: string;
//   intentKey: string;
//   intentValue: string;
//   token: string;
// }

// export const addConversationOption = async ({
//   flowId,
//   intentKey,
//   intentValue,
//   token,
// }: IAddConversationOptionRequest) => {
//   const response = await axios.post(
//     `${config.url}/${apiPrefix}/add_flow_option/${flowId}`,
//     {
//       intent_key: intentKey,
//       intent_value: intentValue,
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       withCredentials: true,
//     }
//   );

//   return response.data;
// };

// interface IDeleteConversationOptionRequest extends IFlowRequest {
//   flowId: string;
//   intentKey: string;
//   intentValue: string;
//   token: string;
// }

// export const deleteConversationOption = async ({
//   flowId,
//   intentKey,
//   intentValue,
//   token,
// }: IDeleteConversationOptionRequest) => {
//   const response = await axios.post(
//     `${config.url}/${apiPrefix}/delete_flow_option/${flowId}`,
//     {
//       intent_key: intentKey,
//       intent_value: intentValue,
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       withCredentials: true,
//     }
//   );

//   return response.data;
// };


export interface PostEngineSessionRequest extends IFlowRequest {
  source_id: string;
  user_id: string;
  current_flow_step?: string;
  current_state?: "INTENT_NOT_STARTED" | "INTENT_AWAITING_RESPONSE";
  data?: {
    [key: string]: string;
  };
}

const createNewSocketUser = async (request: PostEngineSessionRequest) => {
  const response = await axios.post(`${config.url}/${apiPrefix}/session/new`, {
    flow_id: request.flowId,
    ...request,
  });

  return response.data;
};

export const createNewSession = async (request: PostEngineSessionRequest) => {
  const response = await createNewSocketUser(request);

  if (response) return sendEngineMessage("Hi!");

  return;
};

export const sendEngineMessage = async (message: string) => {
  if (message.length === 0) return;

  const response = await axios.post<WidgetMessageInDb>(
    `${SOCKET_URL}/messages`,
    {
      timestamp: 0,
      from_id: SOCKET_USER,
      to_id: SOCKET_BOT,
      type: "text",
      message,
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SOCKET_USER}`,
      },
    }
  );

  return response.data;
};

export const getKnowledgebaseFlags = async (
  flowId: string
): Promise<KnowledgebaseFlags> => {
  const response = await axios.get<KnowledgebaseFlags>(
    `${config.engine}/${engineAPIPrefix}/kb_flags/${flowId}`,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ENGINE_API}`,
      },
    }
  );

  return response.data;
};

export const updateMediaTransfer = async (
  flowId: string,
  transfer: boolean
) => {
  const response = await axios.post(
    `${config.engine}/${engineAPIPrefix}/media_transfer/${flowId}`,
    {},
    {
      params: {
        transfer,
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ENGINE_API}`,
      },
    }
  );

  return response.data;
};

export const updateAgentCopy = async (flowId: string, message?: string) => {
  const response = await axios.post(
    `${config.engine}/${engineAPIPrefix}/agent_transfer_message/${flowId}`,
    {},
    {
      params: {
        message: typeof message === "string" ? message : "",
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ENGINE_API}`,
      },
    }
  );

  return response.data;
};
export const updateThreshold = async (flowId: string, threshold?: number) => {
  const response = await axios.post(
    `${config.engine}/${engineAPIPrefix}/search_threshold/${flowId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ENGINE_API}`,
      },
      params: {
        threshold: typeof threshold === "number" ? threshold : "0.5",
      },
    }
  );

  return response.data;
};

export const updateOutOfOfficeHours = async (
  flowId: string,
  outOfOfficeHours: string[]
) => {
  // if any one of the out of office hours is empty, then we don't update
  if (outOfOfficeHours.some((hour) => hour.length === 0)) outOfOfficeHours = [];

  const response = await axios.post(
    `${config.engine}/${engineAPIPrefix}/out_of_office_timings/${flowId}`,
    outOfOfficeHours,
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ENGINE_API}`,
      },
    }
  );

  return response.data;
};

export const updateOutOfOfficeCopy = async (
  flowId: string,
  message?: string
) => {
  const response = await axios.post(
    `${config.engine}/${engineAPIPrefix}/out_of_office_message/${flowId}`,
    {},
    {
      params: {
        message: typeof message === "string" ? message : "",
      },
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ENGINE_API}`,
      },
    }
  );

  return response.data;
};
