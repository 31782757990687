import axios from "../api/axiosInstance";
import {
  FilterField,
  FilterGroupQuery,
  FilterQuery,
} from "../components/Filter/models";
import config from "../config";
import {
  DashboardTemplate,
  AITurn,
  IntentUnion,
  MicroFlow,
  IFlowRequest,
} from "../models/";

const apiPrefix = "v2/flow";

interface InteractiveRemindersQueryResult {
  start: string;
}

export interface IntentQueryResult {
  response: string;
  acknowledgment: string;
  followup?: string[];
  result?: string;
  temperature: number;
}

export interface PersonaQueryResult {
  persona: string;
  tone: string;
}

export interface MicroFlowQueryResult {
  [name: string]: MicroFlow;
}

export interface NotificationTemplatesQueryResult {
  [reminder_id: string]: DashboardTemplate;
}

interface TagsQueryResult {
  tags: { [x: string]: number };
}

interface StatusQueryResult {
  status: { [x: string]: number };
}

interface SelectionStatusQueryResult {
  selectionStatus: { [x: string]: number };
}

export interface IFlowFilterRequest extends IFlowRequest {
  filters: FilterQuery[];
  abortController?: AbortController;
}

export interface IFlowDashboardRequest extends IFlowRequest {
  filters: FilterQuery[];
  abortController?: AbortController;
}

export interface IMicroFlowSaveRequest extends IFlowRequest {
  flow: MicroFlow;
}

export interface INotificationsQueryRequest extends IFlowRequest {
  flowId: string;
  template: DashboardTemplate;
  filters: FilterGroupQuery;
  step?: string;
  scheduled?: boolean | number;
  data?: { [x: string]: any };
}

export interface IContextUpdateRequest extends IFlowRequest {
  context: string;
}

export interface IIntentQueryRequest extends IFlowRequest {
  intent: IntentUnion;
  userMail: string;
}

export interface RemoteMedia {
  type: "image" | "video" | "document" | "audio";
  link: string;
}

export interface Fact {
  questions: string[];
  answer: string;
  remote_media_attachment?: RemoteMedia[];
  data?: {
    followup_buttons?: string[];
    [x: string]: any;
  };
}

export interface AthenaFact extends Fact {
  kb_id: string;
}

export interface AthenaQuery {
  id: string;
  conversation_id: string;
  name: string;
  message: string;
  timestamp?: number;
}

interface IFactQueryRequest extends IFlowRequest {
  facts: AthenaFact[];
}
interface IFactUpdateRequest extends IFactQueryRequest {
  factID: string;
}

interface IFactsRemoveRequest extends IFlowRequest {
  facts: Fact;
}

interface ISearchFactQueryRequest extends IFlowRequest {
  flowId: string;
  query: string;
}

export const getFlowTags = async (request: IFlowFilterRequest) => {
  const response = await axios.post<TagsQueryResult>(
    `${config.url}/${apiPrefix}/tags/${request.flowId}`,
    request.filters,
    {
      signal: request.abortController?.signal,
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true
    }
  );

  return response.data;
};

export const getFlowStatusBreakdown = async (request: IFlowFilterRequest) => {
  const response = await axios.post<StatusQueryResult>(
    `${config.url}/${apiPrefix}/status/${request.flowId}`,
    request.filters,
    {
      signal: request.abortController?.signal,
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true
    }
  );

  return response.data;
};
export const getFlowSelectionStatusBreakdown = async (request: IFlowFilterRequest) => {
  const response = await axios.post<SelectionStatusQueryResult>(
    `${config.url}/${apiPrefix}/selectionStatus/${request.flowId}`,
    request.filters,
    {
      signal: request.abortController?.signal,
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true
    }
  );

  return response.data;
};

export const getNotificationTemplates = async ({
  flowId,
  token,
}: IFlowRequest) => {
  const response = await axios.get<NotificationTemplatesQueryResult>(
    `${config.url}/v2/flow/templates/notifications/${flowId}`,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;
};

export const saveMicroFlow = async ({
  flow,
  flowId,
  token,
}: IMicroFlowSaveRequest) => {
  const response = await axios.post<InteractiveRemindersQueryResult>(
    `${config.url}/v2/flow/interactive_reminders/${flowId}`,
    flow,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;
};

export const generateAIIntent = async (
  userMail: string,
  body: string,
  persona: string,
  tone: string,
  type: "followup" | "ack" = "ack",
  context: string = "",
  userResponse: string = "",
  prompt_response: string = "",
  retry: boolean = false
) => {
  const response = await axios.post<IntentQueryResult>(
    `${config.url}/${apiPrefix}/intent/new`,
    {
      body,
      user_response: userResponse,
      persona,
      tone,
      context,
      type,
      prompt_response,
      retry,
    },
    {
      headers: {
        "user-id": userMail,
      },
      withCredentials: true
    }
  );

  return response.data;
};

export const createAIComponent = async ({
  intent,
  token,
  userMail,
}: IIntentQueryRequest) => {
  const response = await axios.post<AITurn[]>(
    `${config.url}/${apiPrefix}/intent/new`,
    intent,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "user-id": userMail,
      },
      withCredentials: true
    }
  );

  return response.data;
};

export const generatePersonaTone = async (
  product: string,
  howItWorks: string
) => {
  const response = await axios.post<PersonaQueryResult>(
    `${config.url}/${apiPrefix}/setup/persona_tone`,
    {},
    {
      params: {
        product,
        how_it_works: howItWorks,
      },
    }
  );

  return response.data;
};

export const getMicroFlows = async ({ flowId, token }: IFlowRequest) => {
  const response = await axios.get<MicroFlowQueryResult>(
    `${config.url}/${apiPrefix}/micro_flows/${flowId}`,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;
};

export const updateContext = async (request: IContextUpdateRequest) => {
  const response = await axios.post<{ success: boolean }>(
    `${config.url}/${apiPrefix}/facts/${request.flowId}/context`,
    {},
    {
      params: {
        context: request.context,
      },
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true
    }
  );

  return response.data;
};

export const addFacts = async (request: IFactQueryRequest) => {
  const response = await axios.post<AthenaFact[]>(
    `${config.url}/${apiPrefix}/facts/${request.flowId}/new`,
    request.facts,
    {
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true
    }
  );

  return response.data;
};

export const updateFacts = async (request: IFactUpdateRequest) => {
  const response = await axios.post<AthenaFact[]>(
    `${config.url}/${apiPrefix}/facts/${request.flowId}/update`,
    {
      facts: request.facts,
      factID: request.factID,
    },
    {
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true
    }
  );

  return response.data;
};

export const removeFacts = async (request: IFactsRemoveRequest) => {
  const response = await axios.post<{ status: "success" | "failed" }>(
    `${config.url}/${apiPrefix}/facts/${request.flowId}/remove`,
    request.facts,
    {
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true
    }
  );

  return response.data;
};

export const getFacts = async ({ flowId, token }: IFlowRequest) => {
  const response = await axios.get<{
    facts: AthenaFact[];
    context: string;
  }>(`${config.url}/${apiPrefix}/facts/${flowId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true
  });

  return response.data;
};

export const exportMessageStatus = async ({ flowId, token }: IFlowRequest) => {
  const response = await axios.get(
    `${config.url}/${apiPrefix}/export/${flowId}`,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );
  return response.data;
};

export const getAttributeFilters = async ({ flowId, token }: IFlowRequest) => {
  const response = await axios.get<FilterField[]>(
    `${config.url}/${apiPrefix}/attributes/filters/${flowId}`,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;
};

export const searchFacts = async ({
  flowId,
  query,
  token,
}: ISearchFactQueryRequest) => {
  const response = await axios.get<{ question: string; answer: string }[]>(
    `${config.url}/${apiPrefix}/facts/${flowId}/search`,
    {
      params: { query },
      headers: {
          Authorization: `Bearer ${token}`,
        },
       withCredentials: true,
    }
  );

  return response.data;
};
